import {Box, MenuItem} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useContext} from "react";
import {Field, Form} from "react-final-form";
import {Select, TextField} from "mui-rff";
import Button from "@mui/material/Button";
import {VIDEOS_PATH} from "../../../NetService/doctorConstants";
import api from "../../../Api";
import {FORM_ERROR} from "final-form";
import {useSnackbar} from "notistack";
import {GlobalStateContext} from "../../../hooks/useGlobalSateDoctor";

interface IAddVideosProps {
    isOpen: (isOpen:boolean,upDate?) => void;
    video? : any
}

export const AddVideo = React.memo<IAddVideosProps>(({isOpen ,video})=>{
    const {enqueueSnackbar} = useSnackbar();
    const {selectedProduct} = useContext(GlobalStateContext);


    const onSubmit = async (value: any, form) => {
        const data = {
            "category": value.category,
            "url": value.url,
            "age": value.age,
            "title": value.title
        };
        if(video){
            const url = VIDEOS_PATH + "/" + `${value.id}`;
            try {
                await api.putApi(url, data,selectedProduct.identifier);
                form.reset()
                enqueueSnackbar("Success", {variant: "success"});
                isOpen(false,true)
            } catch (e) {
                if (e instanceof Error) {
                    enqueueSnackbar(e.message, {variant: "error"});
                }
                return {[FORM_ERROR]: "An unexpected error occurred. Please try again."};
            }

        }else{
            try {
                await api.postApi(VIDEOS_PATH, data,selectedProduct.identifier);
                form.reset()
                enqueueSnackbar("Success", {variant: "success"});
                isOpen(false,true)
            } catch (e) {
                if (e instanceof Error) {
                    enqueueSnackbar(e.message, {variant: "error"});
                }
                return {[FORM_ERROR]: "An unexpected error occurred. Please try again."};
            }
        }
        }

    return (
        <Box>
            <Box sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100vh',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000}}>
                <Box sx={{
                    padding: '16px',
                    width: '466px',
                    height: '328px',
                    backgroundColor: 'white',
                }}>
                    <Box sx={{
                        padding: '16px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <p style={{
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            textAlign: 'left'
                        }}>{video ? "Edit" :'New Video'}</p>
                        <CloseIcon style={{cursor:'pointer'}} onClick={() => isOpen(false)}/>
                    </Box>
                    <Form
                        onSubmit={onSubmit}
                        noValidate
                        initialValues={video ? video.value:null}
                        render={({ handleSubmit }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Box sx={{
                                        padding: '16px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px',
                                    }}>
                                        <Field name={'url'}>
                                            {({input}) => (
                                                <Box>
                                                    <TextField
                                                        margin="none"
                                                        required
                                                        fullWidth
                                                        label="Input Youtube link here"
                                                        name="url"
                                                        sx={{
                                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#5000FF',
                                                            },
                                                            '& .MuiInputLabel-root.Mui-focused': {
                                                                color: '#5000FF',
                                                            },
                                                        }}
                                                    />
                                                </Box>
                                            )}
                                        </Field>
                                        <Field name={'title'}>
                                            {({input}) => (
                                                <Box>
                                                    <TextField
                                                        margin="none"
                                                        fullWidth
                                                        label="Name this video"
                                                        name="title"
                                                        sx={{
                                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#5000FF',
                                                            },
                                                            '& .MuiInputLabel-root.Mui-focused': {
                                                                color: '#5000FF',
                                                            },
                                                        }}
                                                    />
                                                </Box>
                                            )}
                                        </Field>
                                        <Box display="flex" gap={'8px'}>
                                            <Select
                                                name="category"
                                                label="Category"
                                                labelId={"category"}
                                                required={true}
                                                sx={{
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#5000FF',
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#5000FF',
                                                    },
                                                    '& .MuiInputLabel-root.Mui-focused': {
                                                        color: '#5000FF',
                                                    },
                                                }}

                                            >
                                                <MenuItem value="Cartoon">Cartoon</MenuItem>
                                                <MenuItem value="Nature">Nature</MenuItem>
                                                <MenuItem value="360VR">360VR</MenuItem>
                                                <MenuItem value="Game">Game</MenuItem>
                                                <MenuItem value="Other">Other</MenuItem>
                                            </Select>
                                            <Select
                                                multiple={true}
                                                name="age"
                                                label="Age range"
                                                labelId={"age"}
                                                required={true}
                                                sx={{
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#5000FF',
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#5000FF',
                                                    },
                                                    '& .MuiInputLabel-root.Mui-focused': {
                                                        color: '#5000FF',
                                                    },
                                                }}
                                            >
                                                <MenuItem value="3-5">3-5</MenuItem>
                                                <MenuItem value="6-12">6-12</MenuItem>
                                                <MenuItem value="13-18">13-18</MenuItem>
                                                <MenuItem value="19-35">19-35</MenuItem>
                                                <MenuItem value="36+">36+</MenuItem>
                                            </Select>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'end',
                                        }}>
                                            <Button variant="contained" size="medium" type={'submit'} sx={{
                                                backgroundColor: 'rgba(51, 55, 255, 1)',
                                                borderRadius:'30px',
                                                fontWeight: 500,
                                                boxShadow:'none',
                                                textTransform:'none'
                                            }}>
                                                {video? 'Edit': 'Add Video'}
                                            </Button>
                                        </Box>
                                    </Box>

                                </form>
                            );
                        }}
                    />
                </Box>
            </Box>
        </Box>
    )
})


