import {STATISTICS_PATH} from "./NetService/doctorConstants";
import {useContext} from "react";
import {GlobalStateContext} from "./hooks/useGlobalSateDoctor";


class Api {
    baseUrl: string;


    constructor() {
        this.baseUrl = process.env.REACT_APP_SERVER_URL || "";
    }

    async request(path: string, options?: RequestInit,identifier?:string) {
        const response = await fetch(`${this.baseUrl}/api/v1/${path}`, options);
        if (response.ok) {
            return response.json();
        }else if(response.status === 401) {
            localStorage.removeItem('mian');
            localStorage.removeItem(identifier || 'Keepy-doctor');
            window.location.reload();
        }

        throw await response.json();
    }

    async updatereview(data, id) {
        return this.request(`procedures/${id}/updatereview`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
    }

    async verify(data) {
        return this.request("customers/verifywithProcedure", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
    }

    async update(data) {
        return this.request("customers/verify", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
    }

    async validate({phoneNumber, code}) {
        return this.request("customers/validate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({phoneNumber, code})
        });
    }


    async resendCode(id: string) {
        return this.request(`customers/${id}/resend`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

    async getVideos(productId, age) {
        return this.request(`products/${productId}/videos?where={"age":"${age}"}`);
    }

    async getVideoQR(productId, body) {
        return this.request(`customers/${productId}/procedures/qr`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        });
    }

    async getClinicUsers(clinicId) {
        return this.request(`clinics/${clinicId}/products?include=user`);
    }

    async getProduct(productId) {
        return this.request(`products/${productId}?include=user`);
    }


    // --------- Doctor
    getDefaultHeaders(identifier?: string) {

        const token = localStorage.getItem(identifier || "main")
        return {"Authorization": `Bearer ${token}`}
    };

    async getApi(url: string,identifier?:string) {
        const params = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                ...this.getDefaultHeaders(identifier)
            }
        }
        return this.request(url, params);
    }

    async putApi(url: string, data: any,identifier?:string) {

        return this.request(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                ...this.getDefaultHeaders(identifier)
            },
            body: JSON.stringify(data)
        })
    }

    async postApi(url: string, data?: any, identifier?: string) {
        return this.request(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                ...this.getDefaultHeaders(identifier)
            },
            body: JSON.stringify(data)
        })
    }


    async deleteApi(url: string, data?: any, identifier?: string) {
        return this.request(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                ...this.getDefaultHeaders(identifier)
            },
            body: JSON.stringify(data)
        })
    }

    async login(url: string, data: any) {
        return this.request(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        })
    }


}


export default new Api();
