import React, { useCallback, useContext }              from "react";
import { Header }                                      from "../../DoctorHeader/Header";
import { Field, Form, FormSpy }                        from "react-final-form";
import api                                             from "../../../Api";
import { FORM_ERROR }                                  from "final-form";
import { TICKETS_PATH }                                from "../../../NetService/doctorConstants";
import { useSnackbar }                                 from "notistack";
import Button                                          from "@mui/material/Button";
import { Box, MenuItem, TextareaAutosize, Typography } from "@mui/material";
import { Select, TextField }                           from "mui-rff";
import DeleteForeverOutlinedIcon                       from "@mui/icons-material/DeleteForeverOutlined";
import AttachFileIcon                                  from "@mui/icons-material/AttachFile";
import { GlobalStateContext }                          from "../../../hooks/useGlobalSateDoctor";

export const ContactUs = React.memo(() => {
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = React.useState(null);
  const { selectedProduct } = useContext(GlobalStateContext);

  const validation = (values: any) => {
    if (!values.title) {
      throw new Error("Please enter a title");
    } else if (!values.message) {
      throw new Error("Please enter a message");
    }
  };

  const onSubmit = async (value: any, form: any) => {
    try {
      validation(value);
      if (!value.title || !value.message) {
        throw new Error();
      }
      await api.postApi(TICKETS_PATH, value, selectedProduct.identifier);
      enqueueSnackbar("Success", { variant: "success" });

    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar(e.message, { variant: "error" });
      }
      return { [ FORM_ERROR ]: "Something went wrong" };
    }
    form.change("title", "");
    form.change("message", "");

  };

  return (
    <Box sx={{

      padding: "16px",
      display: "flex",
      flexDirection: "column",
      width: "100%"
    }}>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        gap: "8px",
        padding: "16px"

      }}>
        <p style={{
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",
          textAlign: "left"

        }}>Contact us</p>
        <Box>
          <p style={{
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "18px",
            textAlign: "left"

          }}>We are always ready to hear from you: <span
            style={{ color: "rgba(51, 55, 255, 1)" }}>+374 44 488 800</span></p>
          <p style={{
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "18px",
            textAlign: "left"

          }}>Working time Mon-Sat , 11AM-5PM , Zone GMT +4.</p>
        </Box>
        <Box sx={{}}>
          <Form

            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Field name="title" type={"select"}>
                  {({ input }) => (
                    <Select
                      sx={{

                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#5000FF"
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#5000FF"
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "#5000FF"
                        },

                        width: "161px"
                      }}
                      {...input} // Connects the Field's input props to the Select component
                      label="Email category"
                    >
                      <MenuItem value={"technicalSupport"}>Technical Support</MenuItem>
                      <MenuItem value={"bugReport"}>Bug report</MenuItem>
                      <MenuItem value={"informationUpdate"}>Information Update</MenuItem>
                      <MenuItem value={"collaborationOffer"}>Collaboration Offer</MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  )}
                </Field>


                <TextField sx={{
                  width: "332px",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#5000FF"
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#5000FF"
                  }
                }} name="message" id="outlined-basic" rows={3} multiline={true} label="Message here..."
                           variant="outlined"/>


                <Box sx={{
                  display: "flex",
                  justifyContent: "end"
                }}>
                  <Button variant="contained" size="medium" type={"submit"} sx={{
                    backgroundColor: "rgba(51, 55, 255, 1)",
                    borderRadius: "30px",
                    fontWeight: 500

                  }}>
                    Send
                  </Button>
                </Box>
              </form>
            )}
          />

        </Box>
      </Box>
    </Box>
  );

});
