import React, { useState } from "react";
import DatePicker          from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Typography } from "@mui/material";
import CloseIcon           from "@mui/icons-material/Close";
import Button              from "@mui/material/Button";

interface DatePickerProps {
  onSubmit: (date: any) => void;
  onCancel: () => void;
}

export const DatePickerCustom = React.memo<DatePickerProps>(({ onSubmit, onCancel }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setEndDate(end);
    setStartDate(start);
  };

  return (
    <Box sx={{
      position: "absolute",
      right: 0,
      top: 20,
      display: "flex",
      flexDirection: "column",
      alignItems: "end"
    }}>
      <button
        onClick={() => onCancel()}
        aria-label="Close Modal"
      >
        <CloseIcon/>
      </button>
      <Box marginTop={"10px"}>
        <DatePicker
          onChange={onChange}
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
        />
      </Box>

      <Button variant="contained" size="medium" type={"submit"} sx={{
        backgroundColor: "rgba(51, 55, 255, 1)",
        borderRadius: "30px",
        fontWeight: 500,
        marginTop: "10px",
        boxShadow: "none",
        width: "137px"

      }} onClick={() => {
        onSubmit({ start: startDate, end: endDate });
      }}>
        <Typography sx={{
          fontFamily: "Poppins"
        }}>
          Applay
        </Typography>
      </Button>
    </Box>
  );
});


