import React, {useCallback, useContext, useState} from "react";
import {Box, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {TextField} from "mui-rff";
import InputAdornment from "@mui/material/InputAdornment";
import KeyIcon from '@mui/icons-material/Key';
import {Field, Form} from "react-final-form";
import Button from "@mui/material/Button";
import {KeyFinder} from "../KeyFinder";
import api from "../../../Api";
import {PRODUCTS_PATH,} from "../../../NetService/doctorConstants";
import {useSnackbar} from "notistack";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import {GlobalStateContext} from "../../../hooks/useGlobalSateDoctor";


interface IAddProductProps {


    isOpen: (open: boolean) => void;
}

export const AddProduct = React.memo<IAddProductProps>(({isOpen}) => {

    const [finder, setFinder] = useState(false);
    const {enqueueSnackbar} = useSnackbar()
    const {selectedProduct} = useContext(GlobalStateContext);
    const keyFinder = useCallback((iOopen) => {
        setFinder(iOopen);
    }, [])
    const handelSubmit = async (values: any) => {
        if (Object.keys(values).length === 3) {
            try {
                const url = `${PRODUCTS_PATH}/add/relatedProduct`;
                await api.putApi(url, values, selectedProduct.identifier);
                enqueueSnackbar("Success", {variant: "success"})
                isOpen(false)
            } catch (e) {
                enqueueSnackbar(e['message'] || "Error", {variant: "error"});

            }
        }
    }

    return !finder ? (<Box>
        <Box sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
        }}>
            <Box>
                <Box sx={{
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    width: '400px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}>
                    <Box sx={{
                        padding: '16px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <Typography style={{
                            fontFamily: 'Poppins',
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: '24px',
                            textAlign: 'left',
                        }}>Add Keppy VR System</Typography>
                        <CloseIcon style={{cursor:'pointer'}} onClick={() => isOpen(false)}/>
                    </Box>
                    <Box>
                        <img alt={"glasses"} src={'../newGlass.webp'}/>
                    </Box>
                    <Box sx={{
                        padding: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                    }}>
                        <Form
                            onSubmit={handelSubmit}
                            render={({handleSubmit}) => (

                                <form onSubmit={handleSubmit}
                                      style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '8px',
                                      }}>
                                    <Field name="email">
                                        {({input, meta}) => (
                                            <Box>
                                                <TextField
                                                    {...input}
                                                    type="text"
                                                    margin="none"
                                                    required
                                                    fullWidth
                                                    label="Key"
                                                    name="Email"
                                                    error={meta.touched && meta.error}
                                                    helperText={meta.touched && meta.error}
                                                    slotProps={{
                                                        input: {
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <LocalPostOfficeOutlinedIcon
                                                                        sx={{color: "#444A6D"}}/>
                                                                </InputAdornment>
                                                            ),
                                                        },
                                                    }}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#5000FF',
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: '#5000FF',
                                                        },
                                                        paddingTop: 0,
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </Field>
                                    <Field name="key">
                                        {({input, meta}) => (
                                            <Box>
                                                <TextField
                                                    {...input}
                                                    type="text"
                                                    margin="none"
                                                    required
                                                    fullWidth
                                                    label="Key"
                                                    name="key"
                                                    error={meta.touched && meta.error}
                                                    helperText={meta.touched && meta.error}
                                                    slotProps={{
                                                        input: {
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <KeyIcon sx={{color: "#444A6D"}}/>
                                                                </InputAdornment>
                                                            ),
                                                        },
                                                    }}
                                                    sx={{
                                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#5000FF',
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: '#5000FF',
                                                        },
                                                        paddingTop: 0,
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </Field>
                                    <Box>
                                        <Field name={'identifier'}>
                                            {({input}) => (
                                                <Box>
                                                    <TextField
                                                        {...input}
                                                        type="text"
                                                        margin="none"
                                                        required
                                                        fullWidth
                                                        placeholder={"Identifier  ex. Room 5"}
                                                        label='Identifier'
                                                        name="name"
                                                        sx={{
                                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#5000FF',
                                                            },
                                                            '& .MuiInputLabel-root.Mui-focused': {
                                                                color: '#5000FF',
                                                            },
                                                        }}
                                                        inputProps={{maxLength: 15}}
                                                    />
                                                </Box>
                                            )}
                                        </Field>
                                    </Box>
                                    <Typography onClick={() => setFinder(true)} style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '14px',
                                        cursor: 'pointer',
                                        fontWeight: 500,
                                        lineHeight: '24px',
                                        textDecorationLine: 'underline',
                                        textAlign: 'end',
                                        color: 'rgba(51, 55, 255, 1)',
                                    }}>Where to find information?</Typography>
                                    <Button variant="contained" size="medium" type={'submit'} sx={{
                                        backgroundColor: 'rgba(51, 55, 255, 1)',
                                        borderRadius: '30px',
                                        fontWeight: 500,
                                        boxShadow: 'none',
                                        textTransform: 'none'
                                    }}>
                                        Add Product
                                    </Button>
                                </form>)}
                        />

                    </Box>
                </Box>
            </Box>
        </Box>
    </Box>) : (<KeyFinder isOpen={keyFinder}/>)


})