import { useEffect }                                                           from "react";
import React, { createContext, ReactNode, useState, Dispatch, SetStateAction } from "react";
import { useAddEmailForm }                                                     from "../../../admin/src/components/pages/Product/Tabs/Emails/AddEmail/useAddEmailForm";

interface AuthContextType {
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
}

export const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  setIsLoggedIn: () => {
  }
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!(localStorage.getItem("main") && localStorage.getItem("Keppy-doctor")));
  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};