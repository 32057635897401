
export  const   VIDEOS_PATH         = `videos`;
export  const   STATISTICS_PATH     = `procedures`;
export  const   REFERRALS_PATH      = `referrals`;
export  const   TEMPLATE_PATH       = `products/feedback/template`;
export  const   TEMPLATE_TEST_PATH  = 'products/feedback/test'
export  const   LOGIN_PATH          = `auth/login`;
export  const   SUBSCRIPTION         = `subscriptions`;
export  const   TICKETS_PATH        = `tickets`;
export  const   PRODUCTS_PATH       = `products`;
export const    PROCEDURES_PATH = 'procedures'
