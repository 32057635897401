import * as React                                  from "react";
import { useContext, useEffect, useRef, useState } from "react";
import Chart                                       from "chart.js/auto";
import api                                         from "../../../Api";
import { Box, Typography }                         from "@mui/material";
import { STATISTICS_PATH }                         from "../../../NetService/doctorConstants";
import Button                                      from "@mui/material/Button";
import CheckIcon                                   from "@mui/icons-material/Check";
import { GlobalStateContext }                      from "../../../hooks/useGlobalSateDoctor";
import {DatePickerCustom} from "../../dateRangePicker/DatePickerCustom";
import {StatCard} from "./StatCard";

interface DataItem {
    duration: number;
    experience: number;
    createdAt: string;
}
interface IDate {
    start?: any;
    end?: any;
}
type Card = {
    total: number;
    day: number;
    duration: number;
    fail: number;
    passed: number
}

interface IBasicArea{
    getUrl: (value:string)=>void
}

const BasicArea = React.memo<IBasicArea>(({getUrl}) => {
    const [chartData, setChartData] = useState({
        xAxis: [],
        failed: [],
        accepted: [],
        total: []
    });
    const [card, setCard] = React.useState<Card>();
    const { selectedProduct } = useContext(GlobalStateContext);
    const [checked, setChecked] = useState<string>("toDay");
    const [date, setDate] = useState<IDate>();
    const canvasRef = useRef(null);
    const chartInstance = useRef(null);
    const [isOpen, setIsOpen] = useState(false);


    function fillDate(data: DataItem[]) {
        const axis: string[] = [];
        const fail: number[] = [];
        const accept: number[] = [];
        const durationArray: number[] = [];

        data.forEach((item: DataItem) => {
            const { duration, experience, createdAt } = item;
            const isoDate = new Date(createdAt);
            const formattedDate = isoDate.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric"
            });

            if (axis[ axis.length - 1 ] !== formattedDate) {
                axis.push(formattedDate);
                fail.push(0);
                accept.push(0);
            }

            if (duration < 120 || experience < 2) {
                fail[ fail.length - 1 ] += 1;
            } else {
                accept[ accept.length - 1 ] += 1;
            }
            durationArray.push(duration);
        });

        const totalArray = fail.map((f, index) => f + accept[ index ]);
        const totalCount = totalArray.reduce((acc, val) => acc + val, 0);

        const cardData = {
            passed: accept.reduce((acc, val) => acc + val, 0),
            fail: fail.reduce((acc, val) => acc + val, 0),
            total: totalCount,
            duration: Math.ceil(durationArray.reduce((acc, val) => acc + val, 0) / durationArray.length),
            day: totalCount / totalArray.length

        };
        if (axis.length === 1) {
            axis.unshift(axis[ 0 ]);
            fail.unshift(fail[ 0 ]);
            totalArray.unshift(totalArray[ 0 ]);
            accept.unshift(accept[ 0 ]);
        }

        const updatedChartData = {
            xAxis: axis,
            failed: fail,
            accepted: accept,
            total: totalArray
        };

        setCard(cardData);
        setChartData(updatedChartData);
    }

    function queryCreator(from: any, to?: any) {
        let whereParam;
        if (to) {
            from = new Date(from);
            to = new Date(to);
            to.setHours(23, 59, 59, 999);
            if (from > to) {
                whereParam = JSON.stringify({
                    createdAt: {
                        "$gt": to.toISOString(),
                        "$lt": from.toISOString()
                    }
                });
            } else {
                whereParam = JSON.stringify({
                    createdAt: {
                        "$gt": from.toISOString(),
                        "$lt": to.toISOString()
                    }
                });
            }

        } else {
            from = new Date(from);
            to = new Date(from);

            to.setHours(23, 59, 59, 999);
            whereParam = JSON.stringify({
                createdAt: {
                    "$gt": from.toISOString(),
                    "$lt": to.toISOString()
                }
            });
        }
        setIsOpen(false)
        return `${STATISTICS_PATH}?where=${whereParam}`;
    }

    const handelButtonClick = (date: string) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        let startDate = new Date(today);

        if (date === "toDay") {
            startDate = new Date(today);
        } else if (date === "yesterday") {
            startDate.setDate(today.getDate() - 1);
        } else if (date === "last7days") {
            startDate.setDate(today.getDate() - 7);
        } else if (date === "last14days") {
            startDate.setDate(today.getDate() - 14);
        } else if (date === "last30days") {
            startDate.setDate(today.getDate() - 30);
        } else if (date === "last90days") {
            startDate.setDate(today.getDate() - 90);
        } else if (date === "thisMonth") {
            startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        } else if (date === "thisYear") {
            startDate = new Date(today.getFullYear(), 0, 1);
        }
        setDate({ start: startDate, end: today });
        setChecked(date);
    };

    useEffect(() => {
        async function getData() {
            let response;
            try {
                if (date) {
                    const query = queryCreator(date.start, date.end)
                    response = await api.getApi(query, selectedProduct.identifier);
                    console.log(query);
                    getUrl(query)


                } else {
                    const currentDate = new Intl.DateTimeFormat().format(new Date());
                    response = await api.getApi(queryCreator(currentDate), selectedProduct.identifier);
                }
                fillDate(response.results);
            } catch (e) {
                console.error("Error fetching data:", e);
            }
        }
        getData();
    }, [date]);

    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext("2d");
            chartInstance.current = new Chart(ctx, {
                type: "line",
                data: {
                    labels: chartData.xAxis,
                    datasets: [
                        {
                            data: chartData.failed,
                            borderColor: "#979797",
                            tension: 0.4,
                            fill: false
                        },
                        {
                            data: chartData.accepted,
                            borderColor: "#96A5B8",
                            tension: 0.4,
                            fill: false
                        },
                        {
                            data: chartData.total,
                            borderColor: "#5000FF",
                            tension: 0.4,
                            fill: false
                        }
                    ]
                },
                options: {
                    plugins: {
                        legend: { display: false }
                    }
                }
            });
        }
    }, [chartData]);

    return (
        <Box sx={{ display: "flex",
            width: "100%",
            gap: "8px",
            height: "100%",
            flexDirection: {
                xs: "column",
                lg: "row"
            }}}>
            <Box sx={{
                display: "flex",
                gap: "8px",
                flexDirection: {
                    xs: "row",
                    lg: "column"
                },
                alignItems: "stretch",
                height: "100%"
            }}>
                    <StatCard text={"Total procedures"} number={card?.total}/>
                    <StatCard text={"Average Per Day"} number={Math.ceil(card?.day)}/>
                    <StatCard text={"Average Duration"} number={card?.duration}/>
                    <StatCard text={"Passed"} number={card?.passed}/>
                    <StatCard text={"Failed"} number={card?.fail}/>
                </Box>

            <Box sx={{
                backgroundColor: "white",
                width: "100%",
                height: "100%"
            }}>
                <Box sx={{
                    width: "100%",
                    padding: "16px",
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <Typography sx={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "24px",
                        textAlign: "left"
                    }}>Statistics per dates</Typography>
                    <Box sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "8px"
                    }}>
                        <Button variant="contained" size="medium" onClick={() => handelButtonClick("toDay")}
                                startIcon={checked === "toDay" ? <CheckIcon sx={{ color: "rgba(23, 207, 135, 1)" }}/> : null}
                                sx={{
                                    height: "32px",
                                    width: "89px",
                                    backgroundColor: checked === "toDay" ? "rgba(23, 207, 135, 0.2)" : "rgba(239, 235, 250, 1)",
                                    border: checked === "toDay" ? "2px solid rgba(23, 207, 135, 0.2)" : null,
                                    borderRadius: "8px",
                                    fontWeight: 500,
                                    color: "black",
                                    textTransform: "none",
                                    boxShadow: "none"
                                }}>Today</Button>
                        <Button variant="contained" size="medium" onClick={() => handelButtonClick("yesterday")}
                                startIcon={checked === "yesterday" ? <CheckIcon/> : null} sx={{
                            height: "32px",
                            width: "95px",
                            backgroundColor: checked === "yesterday" ? "rgba(23, 207, 135, 0.2)" : "rgba(239, 235, 250, 1)",
                            border: checked === "yesterday" ? "2px solid rgba(23, 207, 135, 0.2)" : null,
                            borderRadius: "8px",
                            color: "black",
                            textTransform: "none",
                            boxShadow: "none"
                        }}>Yesterday</Button>
                        <Button variant="contained" size="medium"
                                onClick={() => handelButtonClick("last7days")}
                                startIcon={checked === "last7days" ? <CheckIcon/> : null}
                                sx={{
                                    height: "32px",
                                    width: "150px",
                                    backgroundColor: checked === "last7days" ? "rgba(23, 207, 135, 0.2)" : "rgba(239, 235, 250, 1)",
                                    border: checked === "last7days" ? "2px solid rgba(23, 207, 135, 0.2)" : null,
                                    borderRadius: "8px",
                                    color: "black",
                                    textTransform: "none",
                                    boxShadow: "none"
                                }}>Last 7 days</Button>
                        <Button variant="contained" size="medium"
                                onClick={() => handelButtonClick("last14days")}
                                startIcon={checked === "last14days" ? <CheckIcon/> : null}
                                sx={{
                                    height: "32px",
                                    width: "150px",
                                    backgroundColor: checked === "last14days" ? "rgba(23, 207, 135, 0.2)" : "rgba(239, 235, 250, 1)",
                                    border: checked === "last14days" ? "2px solid rgba(23, 207, 135, 0.2)" : null, borderRadius: "8px",
                                    color: "black",
                                    textTransform: "none",
                                    boxShadow: "none"
                                }}>Last 14 days</Button>
                        <Button variant="contained" size="medium" onClick={() => handelButtonClick("last30days")}
                                startIcon={checked === "last30days" ? <CheckIcon/> : null}
                                sx={{
                                    height: "32px",
                                    width: "150px",
                                    backgroundColor: checked === "last30days" ? "rgba(23, 207, 135, 0.2)" : "rgba(239, 235, 250, 1)",
                                    border: checked === "last30days" ? "2px solid rgba(23, 207, 135, 0.2)" : null,
                                    borderRadius: "8px",
                                    textTransform: "none",
                                    color: "black",
                                    boxShadow: "none"
                                }}>Last 30 days</Button>
                        <Button variant="contained" size="medium"
                                onClick={() => handelButtonClick("last90days")}
                                startIcon={checked === "last90days" ? <CheckIcon/> : null}
                                sx={{
                                    height: "32px",
                                    width: "150px",
                                    backgroundColor: checked === "last90days" ? "rgba(23, 207, 135, 0.2)" : "rgba(239, 235, 250, 1)",
                                    border: checked === "last90days" ? "2px solid rgba(23, 207, 135, 0.2)" : null,
                                    borderRadius: "8px",
                                    color: "black",
                                    textTransform: "none",
                                    boxShadow: "none"
                                }}>Last 90 days</Button>
                        <Button variant="contained" size="medium"
                                onClick={() => handelButtonClick("thisMonth")}
                                startIcon={checked === "thisMonth" ? <CheckIcon/> : null}
                                sx={{
                                    height: "32px",
                                    width: "150px",
                                    backgroundColor: checked === "thisMonth" ? "rgba(23, 207, 135, 0.2)" : "rgba(239, 235, 250, 1)",
                                    border: checked === "thisMonth" ? "2px solid rgba(23, 207, 135, 0.2)" : null,
                                    borderRadius: "8px",
                                    color: "black",
                                    boxShadow: "none",
                                    textTransform: "none"
                                }}>This month</Button>
                        <Button variant="contained" size="medium"
                                onClick={() => handelButtonClick("thisYear")}
                                startIcon={checked === "thisYear" ? <CheckIcon/> : null}
                                sx={{
                                    height: "32px",
                                    width: "133px",
                                    backgroundColor: checked === "thisYear" ? "rgba(23, 207, 135, 0.2)" : "rgba(239, 235, 250, 1)",
                                    border: checked === "thisYear" ? "2px solid rgba(23, 207, 135, 0.2)" : null,
                                    borderRadius: "8px",
                                    color: "black",
                                    boxShadow: "none",
                                    textTransform: "none"
                                }}>This year</Button>
                        <Box sx={
                            {
                                display: "flex",
                                flexDirection: "column",
                                position: "relative"

                            }
                        }>
                            <Button variant="contained" size="medium" sx={{
                                height: "32px",
                                width: "95px",
                                backgroundColor: "rgba(239, 235, 250, 1)",
                                borderRadius: "8px",
                                color: "black",
                                boxShadow: "none",
                                textTransform: "none"

                            }} onClick={() => setIsOpen(true)}>Custom</Button>
                            {isOpen && <DatePickerCustom onSubmit={(date: IDate) => {setDate({
                                start: date.start,
                                end: date.end,
                            })}} onCancel={() => {setIsOpen(false)}} />}
                            {/*{isOpen && <DateRangePicker cancelCal={() => setIsOpen(false)}*/}
                            {/*                            handleSubmit={(e: IDate) => queryCreator(e.start, e.end)}/>}*/}
                        </Box>
                    </Box>
                    <Box sx={{
                        width: "100%",
                        height: "100%"
                    }}>
                        <canvas style={{ width: "100%", maxHeight: "423px" }} ref={canvasRef}></canvas>
                    </Box>
                </Box>
            </Box>
        </Box>);


})

export default BasicArea;
