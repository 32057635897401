import * as React                            from "react";
import Box                                   from "@mui/material/Box";
import InsertChartIcon                       from "@mui/icons-material/InsertChart";
import PermMediaIcon                         from "@mui/icons-material/PermMedia";
import BottomNavigation                      from "@mui/material/BottomNavigation";
import BottomNavigationAction                from "@mui/material/BottomNavigationAction";
import QuickreplyIcon                        from "@mui/icons-material/Quickreply";
import MenuIcon                              from "@mui/icons-material/Menu";
import RecentActorsIcon                      from "@mui/icons-material/RecentActors";
import {IconButton, Menu, MenuItem, Typography} from "@mui/material";
import { useCallback, useContext, useState } from "react";
import { AuthContext }                       from "../../context/Authprovider";
import { useNavigate }                       from "react-router-dom";
import { GlobalStateContext }                from "../../hooks/useGlobalSateDoctor";
import { SigOut }                            from "../PopUps/SigOut";
import ConnectWithoutContactIcon             from "@mui/icons-material/ConnectWithoutContact";
import PermPhoneMsgIcon                      from "@mui/icons-material/PermPhoneMsg";

export const BottomBar = () => {
  const { next } = useContext(GlobalStateContext);
  const navigate = useNavigate();
  const { setIsLoggedIn } = useContext(AuthContext);
  const path = () => window.location.pathname.substring(1);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (nextPage?: string) => {
    setAnchorEl(null);
    if (nextPage === "ref" || nextPage === "sms-conf" || nextPage === "contactus") {
      next(nextPage);
    }
  };
  const sinOut = useCallback((out) => {
    setIsOpen(false);
    if (out) {
      localStorage.removeItem("mian");
      localStorage.removeItem("Keppy-doctor");
      setIsLoggedIn(false);
      navigate("/doctorAdmin/logIn");
    }
  }, []);

  return (
    <Box sx={{
      // zIndex: 1,
      width: "100%",
      position: "fixed",
      bottom: 0
    }}><Box sx={{ width: "100%" }}>
        <Box sx={{
          display: "flex",
          height: "80px",
          backgroundColor: " rgba(18, 20, 33, 1)",
          paddingLeft: "8px",
          paddingRight: "8px",
          justifyContent: "space-around",
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: 'relative',
            paddingTop: "12px",
            paddingBottom: "16px",
            cursor: "pointer",
          }}  onClick={() => {
            next("statistics");
          }}>
            <Box sx={{
              display: path() === "doctorAdmin/statistics" ? "" : "none",
              position: "absolute",
              top: "2px",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "40px",
              height: "5px",
              backgroundColor: path() === "doctorAdmin/statistics" ? "rgba(122, 125, 255, 1)" : "",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px"
            }}/>
            <InsertChartIcon sx={{
              color: path() === "doctorAdmin/statistics" ? "rgba(122, 125, 255, 1)" : "white"
            }}/>
            <Typography sx={{
              color: path() === "doctorAdmin/statistics" ? "rgba(122, 125, 255, 1)" : "white",
            }} >
              Statistics
            </Typography>
          </Box>


          <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: 'relative',
            paddingTop: "12px",
            paddingBottom: "16px",
            cursor: "pointer",
          }}
               onClick={() => {
                 next("patients");
               }}>
            <Box sx={{
              display: path() === "doctorAdmin/patients" ? "" : "none",
              position: "absolute",
              top: "2px",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "40px",
              height: "5px",
              backgroundColor: path() === "doctorAdmin/patients" ? "rgba(122, 125, 255, 1)" : "",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px"
            }}/>
            <RecentActorsIcon sx={{ color: path() === "doctorAdmin/patients" ? "rgba(122, 125, 255, 1)" : "white" }}/>
            <Typography sx={{
              color: path() === "doctorAdmin/patients" ? "rgba(122, 125, 255, 1)" : "white",
            }} >
              Patients
            </Typography>
          </Box>

          <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: 'relative',
            paddingTop: "12px",
            paddingBottom: "16px",
            cursor: "pointer",
          }}
               onClick={() => {
                 next("sms-conf");
               }}>
            <Box sx={{
              display: path() === "doctorAdmin/sms-conf" ? "" : "none",
              position: "absolute",
              top: "2px",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "40px",
              height: "5px",
              backgroundColor: path() === "doctorAdmin/sms-conf" ? "rgba(122, 125, 255, 1)" : "",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px"
            }}/>
            <QuickreplyIcon
                sx={{
                  color: path() === "doctorAdmin/sms-conf" ? "rgba(122, 125, 255, 1)" : "white"
                }}/>
            <Typography sx={{
              color: path() === "doctorAdmin/sms-conf" ? "rgba(122, 125, 255, 1)" : "white",
            }} >
              Communication
            </Typography>
          </Box>

          <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: 'relative',
            paddingTop: "12px",
            paddingBottom: "16px",
            cursor: "pointer",
          }}
               onClick={() => {
                 next("content");
               }}>
            <Box sx={{
              display: path() === "doctorAdmin/content" ? "" : "none",
              position: "absolute",
              top: "2px",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "40px",
              height: "5px",
              backgroundColor: path() === "doctorAdmin/content" ? "rgba(122, 125, 255, 1)" : "",
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px"
            }}/>
            <PermMediaIcon
                sx={{
                  color: path() === "doctorAdmin/content" ? "rgba(122, 125, 255, 1)" : "white"
                }}/>
            <Typography sx={{
              color: path() === "doctorAdmin/content" ? "rgba(122, 125, 255, 1)" : "white",
            }} >
              Media
            </Typography>
          </Box>

          <Box>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                  cursor: "pointer",
                }}
            >
              <MenuIcon sx={{ color: open ? "rgba(122, 125, 255, 1)" : "white" }} />
              <Typography sx={{
                color: "white",
              }}>
                More
              </Typography>
            </IconButton>
            <Menu
                id="composition-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <MenuItem onClick={()=>handleClose('contactus')}>
                <Box display="flex" gap={"12px"}>
                  <PermPhoneMsgIcon sx={{ fontSize: 24, color: "rgba(68, 74, 109, 1)" }} />
                  Contact us
                </Box>
              </MenuItem>

              <MenuItem onClick={()=>handleClose('ref')}>
                <Box display="flex" gap={"12px"}>
                  <ConnectWithoutContactIcon sx={{ fontSize: 24, color: "rgba(68, 74, 109, 1)" }} />
                  Refer to
                </Box>
              </MenuItem>
              <MenuItem onClick={()=>handleClose}>Sign out</MenuItem>

            </Menu>
          </Box>
      </Box>
      {isOpen && <SigOut isOpen={sinOut}/>}
    </Box>

    </Box>
  );
};




