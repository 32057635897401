import { format }                             from "date-fns";
import * as React                             from "react";
import api                                    from "../../../Api";
import { STATISTICS_PATH }                    from "../../../NetService/doctorConstants";
import {useCallback, useContext, useEffect, useMemo} from "react";
import { StatCard }                           from "./StatCard";
import DateRangePicker                        from "../../dateRangePicker/DateRangePicker";
import { Header }                             from "../../DoctorHeader/Header";
import { MyModal }                            from "../../DoctorModal/Modal";
import CalendarMonthIcon                      from "@mui/icons-material/CalendarMonth";
import { Tables }                             from "../../Table";
import { EditModal }                            from "../../DoctorModal/EditModal";
import { TableTest }                            from "../../DoctorTable";
import { PersonalInfo }                         from "../../PersonalInfo";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import Avatar                                   from "@mui/material/Avatar";
import Table                                    from "@mui/material/Table";
import OpenInNewOutlinedIcon                    from "@mui/icons-material/OpenInNewOutlined";
import EditNoteIcon                             from "@mui/icons-material/EditNote";
import DeleteForeverOutlinedIcon                from "@mui/icons-material/DeleteForeverOutlined";
import BasicArea                                from "./diagram";
import { EditProcedure }                        from "../../PopUps/EditProcedure";
import { GlobalStateContext }                   from "../../../hooks/useGlobalSateDoctor";
import logoPage                                 from "../../Page/LogoPage";

type TableState = {
  id: string;
  name: string;
  createdAt: string;
  product: string;
  qr: string;
  customer?:{
    firstName?: string;
    lastName?: string;
    phoneNumber: string;
    gender: string;
  };
  age: string;
  own: string;
  url: string;
  failed: string;
  duration: string;
  experience: string;
  procedure: string;
  problem: number;
  followup: string;
  same: string;
  procedureType: string;
  nextVisit?: string;
}

export const Statistics = React.memo(() => {
  const { setFilterUrl,selectedProduct, next, expiredDays } = useContext(GlobalStateContext);
  const [isOpen, setIsOpen] = React.useState<TableState | null>();
  const [tableData, setTableData] = React.useState<TableState[]>();
  const headers = [
    {
      field: "name",
      headStyle: {
        width: "150px"
      },
      renderCell: ({ row }) => {
        const customerName = row.name
            ||'-';
        return <Box>{customerName}</Box>;
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerName: "Name",
      headerClassName: "table-header"
    },
    {
      field: "createdAt",
      headerName: "Created",
      renderCell: ({ row }) => {
        return(<Box>
          {row.updatedAt
              ? format(new Date(row.updatedAt), "dd.MM.yyyy")
              : "-"}
              </Box>)
      },
      headStyle: {
        width: "150px"
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    },
    {
      field: "qr",
      headerName: "QR Scan",
      renderCell: (params)=>{
        return (
            <Box>
              {params.row.qr ? params.row.qr : 'false'}
            </Box>
        )
      },
      headStyle: {
        width: "90px"
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    },
    {
      field: "age",
      headerName: "Age",
      renderCell: (params: any)=>{
        return (
            <Box>
              {params?.row?.customer?.age || '-'}
            </Box>
        )
      },
      headStyle: {
        width: "90px"
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header",

    },
    {
      field: "own",
      headerName: "Own",
      headStyle: {
        width: "90px"

      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    },
    {
      field: "url",
      headerName: "Media",
      headStyle: {
        width: "20%"
      },
      cellStyle: {
        width: "120px",
        whiteSpace: "nowrap"
      },
      renderCell: (param: any) => {
        return (
          <a href={param.value} target={"_blank"} style={{
            color: "rgba(51, 55, 255, 1)"
          }}>{param.value}</a>
        );
      },
      headerClassName: "table-header"
    },
    {
      field: "failed",
      headerName: "Failed",
      headStyle: {
        width: "90px"
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    },
    {
      field: "experience",
      headerName: "Experience",
      headStyle: {
        width: "90px"
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    },
    {
      field: "procedureType",
      headerName: "Procedure",
      headStyle: {
        width: "90px"
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    },
    {
      field: "problem",
      headerName: "Problem",
      headStyle: {
        width: "90px",
        textAlign: "center"

      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    },
    {
      field: "followup",
      headerName: "Follow Up",
      headStyle: {

        whiteSpace: "break-spaces",
        width: "90px"
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    },
    {
      field: "same",
      headerName: "Same",
      headStyle: {
        width: "90px"
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    }

  ];
  const editHeader = [
    {
      field: "actions",
      headerName: "Actions",
      cellStyle: {
        position: "absolute",
        right: "0px"
      },
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "table-header",
      renderCell: (params: any) => {
        const onEdit = (e: any) => {
          e.stopPropagation();
          setIsOpen(params.value);
        };
        return (
          <Box display={"flex"}>
            <Tooltip title={"Edit"} arrow>
              <IconButton disableFocusRipple={true} onClick={onEdit} sx={{ color: "#444A6D" }}>
                <EditNoteIcon/>
              </IconButton>
            </Tooltip>
          </Box>
        );
      }
    }
  ];

  async function getData(url?: string) {
    try {
      if(url){
        const result = await api.getApi(url, selectedProduct.identifier);
        setFilterUrl(url)
        setTableData(result.results);
      }else{
        const result = await api.getApi(`${STATISTICS_PATH}?limit=10&sort={"createdAt":-1}&include=user,customer,product`, selectedProduct.identifier);
        setTableData(result.results);
      }
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    if(Object.keys(selectedProduct).length){
      getData();
    }
  }, [selectedProduct.id]);

  const getUrl = useCallback((url) => {
    getData(url+'&limit=10&sort={"createdAt":-1}&include=user,customer,product')
  },[]);



  if (Object.keys(selectedProduct).length) {
    return (
      <Box
        sx={{ padding: "16px", display: "flex", flexDirection: "column", gap: "16px", width: "100%", overflowX: "scroll" }}>
        <Box sx={{ backgroundColor: "white", display: "flex" }}>
          <Box sx={{
            padding: "16px", display: "flex", borderRight: "2px solid #F1F1F4", width: "60%", justifyContent: {
              xs: "center",
              md: "start"
            }
          }}>
            <Box sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row"
              },
              justifyContent: {
                xs: "center",
                md: "start"
              },
              alignItems: "center",
              gap: {
                xs: "8px",
                md: "32px"
              }
            }}>
              <Avatar sx={{
                backgroundColor: "rgba(233, 233, 233, 1)",
                width: {
                  xs: "56px",
                  md: "90px"
                },
                height: {
                  xs: "56px",
                  md: "90px"
                },
                "& .MuiAvatar-img": {
                  width: {
                    xs: "45.82px",
                    md: "73.68px"
                  },
                  height: {
                    xs: "17.18px",
                    md: "27.61px"
                  }
                }
              }} src="/glass.webp"/>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px"
              }}>
                <Typography sx={{
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontWeight: 500,
                  lineHeight: "27px",
                  textAlign: "left",
                  backgroundColor: "#F1F1F4",
                  padding: "8px",
                  borderRadius: "8px"
                }}>{selectedProduct.activationKey || '-'}</Typography>
                <Box sx={{ display: "flex", gap: "16px" }}>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                    <Typography sx={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      textAlign: "left"
                    }}>Model</Typography>
                    <Typography sx={{
                      fontFamily: "Poppins",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "24px"
                    }}>{selectedProduct?.subscription.productType.slice(-3) || '-'}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                    <Typography sx={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px"
                    }}>Subscription</Typography>
                    <Typography sx={{
                      fontFamily: "Poppins",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "24px"
                    }}>{selectedProduct?.subscription.subscription || '-'}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                    <Typography sx={{
                      fontSize: "12px",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      lineHeight: "18px",
                      textAlign: "left"
                    }}>Days</Typography>
                    <Typography sx={{
                      fontFamily: "Poppins",
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "24px",
                      color: expiredDays < 30 ? "rgba(181, 54, 0, 1)" : "black"
                    }}>{expiredDays || '-'}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{
            width: "100%",
            display: "flex",
            gap: "16px",
            justifyContent: "start",
            alignItems: "center",
            paddingLeft: "32px",
            position: "relative"

          }}>
            <Box sx={{
              display: "flex",
              position: "absolute",
              right: "10px",
              top: "10px"

            }}>

            </Box>
            <Box>
              <Box display="flex" width={"100%"} gap={"16px"} justifyContent={"space-between"} alignItems="center">
                <Typography sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "18px",
                  minWidth: "80px"
                }}>First name</Typography>
                <Typography sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  textAlign: "left",
                  flex: 1
                }}>{selectedProduct?.user.firstName || '-'}
                </Typography>
              </Box>
              <Box display="flex" width={"100%"} gap={"16px"} justifyContent={"space-between"} alignItems="center">
                <Typography sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "18px",
                  minWidth: "80px"
                }}>Last name</Typography>
                <Typography sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  textAlign: "left",
                  flex: 1

                }}>{selectedProduct?.user.lastName || '-'}</Typography>
              </Box>
              <Box display="flex" width={"100%"} gap={"16px"} justifyContent={"space-between"} alignItems="center">
                <Typography sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "18px",
                  minWidth: "80px"
                }}>Email </Typography>
                <Typography sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  textAlign: "left",
                  flex: 1
                }}>    {selectedProduct?.user.email || '-'}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              <Box display="flex" width={"100%"} gap={"16px"} justifyContent={"space-between"} alignItems="center">
                <Typography sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "18px",
                  minWidth: "80px",
                  textAlign: "left"
                }}>Phone </Typography>
                <Typography sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  textAlign: "left",
                  flex: 1

                }}>   {selectedProduct?.user.phoneNumber || '-'}
                </Typography>
              </Box>
              <Box display="flex" width={"100%"} gap={"16px"} justifyContent={"space-between"} alignItems="center">
                <Typography sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "18px",
                  minWidth: "80px",
                  textAlign: "left"
                }}>Registered </Typography>
                <Typography sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  textAlign: "left",
                  flex: 1

                }}> {format(new Date(selectedProduct?.subscription?.createdAt), "dd.MM.yyyy") || '-'}
                </Typography>
              </Box>
              <Box display="flex" width={"100%"} gap={"16px"} justifyContent={"space-between"} alignItems="center">
                <Typography sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "18px",
                  minWidth: "80px",
                  textAlign: "left"
                }}>Expired </Typography>
                <Typography sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  textAlign: "left",
                  flex: 1
                }}>{format(new Date(selectedProduct?.subscription?.expiredAt), "dd.MM.yyyy") || '-'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <BasicArea  getUrl={getUrl}/>
        {tableData?.length > 0 && (
            <Box sx={{
              backgroundColor: "white",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              gap: "16px"
            }}>
              <Box sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between"
              }}>

                {

                }
                <Typography sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  textAlign: "left"
                }}>Patients</Typography>
                <Box sx={{
                  display: "flex",
                  gap: "8px"

                }}>
                  <Typography
                      onClick={() => next("patients")}
                      sx={{
                        fontFamily: "Poppins",
                        color: "#3337FF",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "24px",
                        textAlign: "left",
                        cursor: "pointer",
                        display: "flex",
                        gap: "8px"
                      }}
                  >See full list </Typography>
                  <Typography><OpenInNewOutlinedIcon sx={{
                    textAlign: "center",
                    color: "#3337FF",
                    width: "16px",
                    height: "16px"
                  }}/></Typography>
                </Box>

              </Box>


              {isOpen && <EditProcedure handelOpen={setIsOpen} data={isOpen} getData={getData}/>}
              {tableData && <Box sx={{ width: "100%" }}> <Tables headers={headers} rows={tableData} editHeader={editHeader}/> </Box>}
            </Box>
        )}
      </Box>
    );
  }
  return <></>
});