import { useMemo }                                               from "react";
import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate }                                           from "react-router-dom";
import api                                                       from "../Api";
import { PRODUCTS_PATH }                                         from "../NetService/doctorConstants";
import { AuthContext }                                           from "../context/Authprovider";
import { format }                                                from "date-fns";
import {elements} from "chart.js";

export type Next = (path: string, data?: UserData) => void;

interface UserData {
  user: object;
  subscription: object;
  accessToken: string;
}

interface AuthContextType {
  stateGlobal: any;
  selectedProduct: any
  filterUrl: any
  setFilterUrl(filterData: any): void
  setSelectedProduct(identifier: string): void
  next: (path: string, data?: UserData) => void
  expiredDays: number
}

function calculateDaysDifference(targetDateStr: string): number {
  // Parse the target date (assuming it's in dd.MM.yyyy format)
  const [day, month, year] = targetDateStr.split(".").map(Number);
  const targetDate = new Date(year, month - 1, day);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const diffInMs = targetDate.getTime() - currentDate.getTime();

  // Convert milliseconds to days
  const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

  return diffInDays;
}

export const GlobalStateContext = createContext<AuthContextType>({
  filterUrl: '',
  setFilterUrl: ()=>{

  },
  selectedProduct: {},
  setSelectedProduct: () => {
  },
  stateGlobal: {},
  next: (path: string, data?: UserData) => {
  },
  expiredDays: 0
});

export const AuthProviderDoctor = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const [stateGlobal, setStateGlobal] = useState<object>({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [filterUrl, setFilterUrl] = useState('');
  const { isLoggedIn } = useContext(AuthContext);



  useEffect(() => {
    const fetchData = async () => {
      const data = localStorage.getItem("Keppy-doctor");
      const productId = JSON.parse(data).subscription.product.id;
      try {
        const results = await api.getApi(`${PRODUCTS_PATH}/relatedProducts?include=user,subscription`);
        if (results.length) {
          let main = {};
          let relatedProducts = [];
          results.forEach(p => {
            if (p.id === productId) {
              main = p;
            } else {
              relatedProducts.push(p);
            }
          });
          main[ "relatedProducts" ] = (main[ "relatedProducts" ] || []).map(rP => {
            const fullRP = relatedProducts.find(r => r.activationKey === rP.key);
            return { ...rP, ...fullRP };
          });
          setStateGlobal(main);
          setSelectedProduct(main);
        }
      } catch (error) {
        console.error("Error fetching related products:", error);
      }
    };
    if (isLoggedIn) {
      fetchData();
    }
  }, [isLoggedIn]);
  const expiredDays = useMemo(() => {
    if (Object.keys(selectedProduct).length) {
      const expiredAt = format(new Date(selectedProduct[ "subscription" ].expiredAt), "dd.MM.yyyy");
      return calculateDaysDifference(expiredAt);
    }
    return 0;
  }, [selectedProduct[ "subscription" ]?.expiredAt]);
  const next: Next = (path?: string, data: any = null,cData?:any) => {

    if (data) {
      localStorage.setItem("Keppy-doctor", JSON.stringify(data));
      localStorage.setItem("main", data.accessToken);
    }
    if(cData){
      setFilterUrl(cData)
    }
    navigate(`/doctorAdmin/${path}`);
  };
  setInterval(async () => {
    const resualt = await api.getApi("products/me?include=subscription", selectedProduct[ "identifier" ]);
    try {
      if (Object.keys(selectedProduct).length !== 0 && selectedProduct[ "subscription" ].expiredAt === resualt.subscription?.expiredAt) {
        let sP = {...selectedProduct}
        sP[ "subscription" ] = {...sP[ "subscription" ], ...resualt.subscription}
        setSelectedProduct(sP)
        let finder =  stateGlobal['relatedProducts'].find((elements)=> elements.key === selectedProduct['activationKey'])
        if(!finder) {
          stateGlobal['subscription'] = resualt.subscription
        }else{
          let newG = {...stateGlobal}
          newG['relatedProducts'] = [...finder['subscription'], ...resualt.subscription];
          setStateGlobal(newG);
        }
        //todo Mihat Stugi eli

      }
    } catch (error) {
      console.log("Error fetching Sub products", error);
    }
  // * 60 * 60 * 8
  }, 1000 * 60 * 60 * 8 );

  return (
    <GlobalStateContext.Provider value={{filterUrl, setFilterUrl, selectedProduct, setSelectedProduct, stateGlobal, next, expiredDays }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
