import React, {useEffect} from "react";
import {Field, Form, FormSpy} from "react-final-form";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import {Avatar, Box, FormGroup, FormHelperText, RadioGroup, Typography} from "@mui/material";
import Switch from '@mui/material/Switch';
import FormControl from "@mui/material/FormControl";
import {FormApi} from "final-form";


type FeedbackState = {
    [key: string]:{feedback: string;
        enabled: any;
        link: any;
        reviewPlatform: string;}

};


interface CardProps {

    id?: string,
    surname: string,
    status?: string,
    data?: FeedbackState
    aKey: string
    form : FormApi
}


export const Card = React.memo<CardProps>(({form,aKey ,surname,data}) => {
    return (
            <Box sx={{
                maxWidth: '264px',
            }}>
                <Box sx={{ display: 'flex', gap:'8px',padding:"8px", backgroundColor: "#E6E6E6", justifyContent:"space-between"}}>
                    <Box sx={{display:"flex", gap:'8px', alignItems:'center'}}>
                        <Avatar sx={{
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            width: "24px",
                            height: "24px",
                            "& .MuiAvatar-img": {
                                width: "24px",
                                height: "9px"
                            }
                        }} src="/glass.webp"/>
                        <span style={{
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            textAlign: 'left',

                        }}>Dr. {surname}</span>
                    </Box>
                    <Field name={`${aKey}.enabled`} type='checkbox'>
                        {({input}) => (
                            <Switch
                                sx={{
                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                        backgroundColor: 'rgb(51, 55, 255)',
                                        border: '1px solid rgb(51, 55, 255)',


                                    },
                                    '& .MuiSwitch-thumb': {
                                        backgroundColor: 'rgb(51, 55, 255)'
                                    },
                                    '& .Mui-checked .MuiSwitch-thumb': {
                                        backgroundColor: 'white',
                                    },

                                    '& .MuiSwitch-track': {
                                        border: '1px solid rgba(167, 167, 167, 1)',
                                        backgroundColor: 'white',
                                        width: '52px',

                                    }
                                }}
                                {...input}
                            />
                        )}
                    </Field>
                </Box>
                <Box sx={{display:"flex", gap:'8px', backgroundColor:'#F1F1F4'}}>
                    <Box sx={{display:'flex', flexDirection:"column", padding:'16px',  gap: '8px'}}>
                        <Typography sx={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '24px',
                            textAlign: 'left',
                        }}>Review platform</Typography>
                        <Box sx={{display:"flex",
                            flexDirection:"column",
                            gap:'8px'}}>
                            <Field name={`${aKey}.reviewPlatform`}
                                   validate={(value, allValues) => {
                                       const isEnabled = allValues?.[aKey]?.enabled;
                                       return isEnabled && !value ? "This field is required." : undefined;
                                   }}                           >
                                {({ input, meta }) => (
                                    <FormControl

                                    >
                                        <RadioGroup
                                            {...input}
                                            onChange={(_,value)=>{
                                                form.change(`${aKey}.link`,'')
                                                form.change(`${aKey}.reviewPlatform`,value)
                                            }}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '8px',
                                                alignItems: 'center',
                                            }}
                                            aria-labelledby="controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                        >
                                            <FormControlLabel
                                                value="yelp"
                                                control={
                                                    <Radio
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: 'rgba(51, 55, 255, 1)',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Yelp"
                                            />
                                            <FormControlLabel
                                                value="google"
                                                control={
                                                    <Radio
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: 'rgba(51, 55, 255, 1)',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Google"
                                            />
                                        </RadioGroup>
                                        {/* Display error message if validation fails */}
                                        {data[aKey]?.enabled && meta.touched && !input.value && (
                                            <FormHelperText sx={{
                                                textAlign: 'left',
                                                color: 'red',
                                            }}>Please select a review platform.</FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            </Field>

                            <Field name={`${aKey}.link`}  type='input'>
                                {({input, }) => (
                                    <TextField name={`${aKey}.link`} id="outlined-basic" label="Add link" variant="outlined"
                                               {...input}
                                               sx={{
                                        '& .MuiInputBase-input':{
                                            backgroundColor: '#FFFFFF',
                                        },
                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#5000FF',
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#5000FF',
                                        },
                                    }}
                                        required={data[aKey]?.enabled}/>
                                )}
                            </Field>

                        </Box>
                        <Box>
                            <Typography sx={{
                                fontFamily: 'Poppins',
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '24px',
                                textAlign: 'left',
                            }}>Procedure Feedback</Typography>
                            <Typography sx={{
                                fontFamily: 'Poppins',
                                fontSize: '12px',
                                fontWeight: 400,
                                lineHeight: '18px',
                                textAlign: 'left',
                            }}>Patients will be able to estimate your procedure.</Typography>
                            <Field name={`${aKey}.feedback`} type="checkbox">
                                {({ input,meta }) => (
                                    <FormGroup>
                                        <FormControlLabel
                                            required={data[aKey]?.enabled}
                                            control={
                                                <Checkbox
                                                    {...input}
                                                    sx={{
                                                        "&.Mui-checked": {
                                                            color: "rgba(51, 55, 255, 1)",
                                                        },
                                                    }}
                                                />
                                            }
                                            label="Enable service"
                                        />
                                    </FormGroup>
                                )}
                            </Field>
                        </Box>
                    </Box>
                </Box>
            </Box>
    );
});


// <main className={"shadow-lg flex h-full justify-center w-full"}>
//     <div className={" p-4 h-full rounded-lg pointer-events-auto"}>
//         <div
//             className={`rounded-t-lg ${feedbackTemplates?.enabled ? "bg-blue-primary" : "bg-gray-light"} flex p-4 justify-between`}>
//             <div>
//                 <p className={"text-white text-fontFamily-poppins"}>UserID: {id}</p>
//                 <p className={"text-white text-fontFamily-poppins"}>Dr.{surname}</p>
//                 <p className={"text-white text-fontFamily-poppins"}>Status:<span
//                     className={"underline"}>{feedbackTemplates?.enabled ? "Enable" : "Disable"}</span></p>
//             </div>
//
//             <div onClick={() => {
//                 setEdit(!edit);
//             }}>
//                 <div
//                     className={"w-10 h-10 bg-white rounded-lg flex justify-center items-center cursor-pointer"}>
//                     <EditIcon sx={{color: "#5000FF"}}/>
//                 </div>
//             </div>
//
//         </div>
//         <div className={"h-2/3"}>
//             <Form
//                 onSubmit={onSubmit}
//                 initialValues={feedbackTemplates ? feedbackTemplates : {
//
//                     enabled: true,
//                     feedback: "",
//                     reviewPlatform: "",
//                     link: ""
//
//                 }}
//                 render={({handleSubmit, form, submitSucceeded}) => (
//                     <form onSubmit={handleSubmit} className={"h-full"}>
//                         <div className={"p-2 w-full gap-2 flex flex-col justify-between"}>
//                             <div>
//                                 <label>
//
//                                     <Field name="enabled" type="checkbox"
//                                            disabled={!edit}>
//                                         {({input}) => (
//                                             <FormControlLabel
//                                                 control={
//                                                     <Checkbox
//                                                         {...input}
//                                                         sx={{
//                                                             '&.Mui-checked .MuiSvgIcon-root': {fill: '#5000FF'},
//                                                         }}
//                                                         disabled={!edit}
//                                                     />
//                                                 }
//                                                 label={'Enable SMS services'}
//                                             />
//                                         )}
//
//                                     </Field>
//                                 </label>
//                             </div>
//                             <div>
//                                 <p className={` ${!edit ? "text-gray-lightDoctor" : ""}`}>Select the review
//                                     platforms</p>
//                                 <div className={"flex mt-3"}>
//                                     <Field
//                                         name="reviewPlatform"
//                                         type="radio"
//                                         value="yelp"
//                                         disabled={!edit}
//                                         render={({input, meta}) => (
//
//                                             <FormControlLabel control={<Radio {...input} disabled={!edit} sx={{
//                                                 '&.Mui-checked .MuiSvgIcon-root': {fill: '#5000FF'}
//                                             }}/>}
//                                                               label="Yelp"/>
//                                         )}
//                                     />
//
//
//                                     <Field name="reviewPlatform" type="radio"
//                                            value="google" disabled={!edit} render={({input}) => (
//                                         <FormControlLabel control={<Radio {...input} disabled={!edit} sx={{
//                                             '&.Mui-checked .MuiSvgIcon-root': {fill: '#5000FF'}
//                                         }}/>}
//                                                           label="Google Review"/>)}/>
//
//
//                                 </div>
//                             </div>
//                             <div>
//                                 <Field name={"link"}>
//                                     {({input}) => (
//                                         <TextField label="Fill in Yelp review link" variant="outlined"
//                                                    {...input}
//                                                    disabled={!edit}
//                                                    className={'w-full'}
//                                                    sx={{
//                                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
//                                                            borderColor: '#5000FF',
//                                                        },
//                                                        '& .MuiInputLabel-root.Mui-focused': {
//                                                            color: '#5000FF',
//                                                        }
//                                                    }}/>)}
//                                 </Field>
//
//
//                                 {/*<Field name={'text'} component={'textarea'}>*/}
//                                 {/*    {({input, meta}) => (<AutoResizeTextArea input={input} meta={meta}*/}
//                                 {/*                                             className={'h-32 placeholder-black border border-gray-light'}*/}
//                                 {/*                                             maxLength={20}*/}
//                                 {/*                                             disabled={!selected}*/}
//                                 {/*                                             placeholder={'Text 20 character max'}/>)}*/}
//                                 {/*</Field>*/}
//                             </div>
//                             <div>
//                                 <p className={` ${!edit ? "text-gray-lightDoctor" : ""}`}>Do you want to send
//                                     feedback</p>
//                                 <div className={"flex mt-1"}>
//                                     <label className={"flex items-center cursor-pointer"}>
//
//                                         <Field name="feedback" type="radio" value={"yes"}
//                                                disabled={!edit}
//                                                render={({input, meta}) => (
//
//                                                    <FormControlLabel
//                                                        control={<Radio {...input} disabled={!edit} sx={{
//                                                            '&.Mui-checked .MuiSvgIcon-root': {fill: '#5000FF'}
//                                                        }}/>}
//                                                        label="Yes"/>
//                                                )}/>
//
//                                     </label>
//                                     <label className={"flex items-center cursor-pointer ml-1"}>
//                                         <Field name="feedback" type="radio" value={"no"}
//                                                disabled={!edit} render={({input, meta}) => (
//
//                                             <FormControlLabel control={<Radio {...input} disabled={!edit} sx={{
//                                                 '&.Mui-checked .MuiSvgIcon-root': {fill: '#5000FF'}
//                                             }}/>}
//                                                               label="No"/>
//                                         )}/>
//
//                                     </label>
//                                 </div>
//                             </div>
//                             <div>
//                                 {/*<p className={`${!selected? 'text-gray-lightDoctor':""}`}>Add a mobile number to test SMS</p>*/}
//                                 <div className={" mt-3"}>
//                                     {/*<div className={'flex gap-3 xl:flex-row'}>*/}
//                                     {/*    <Field name={'numberCode'}>*/}
//                                     {/*        {({input}) => (*/}
//                                     {/*            <Input*/}
//                                     {/*                {...input}*/}
//                                     {/*                classNameDiv={'mr-3'}*/}
//                                     {/*                className={'w-20 p-4 placeholder-black border border-gray-light'}*/}
//                                     {/*                placeholder={'+'}*/}
//                                     {/*                maxLength={3}*/}
//                                     {/*                disabled={!selected}*/}
//                                     {/*            />*/}
//                                     {/*        )}*/}
//                                     {/*    </Field>*/}
//                                     {/*    <Field name={'number'}>*/}
//                                     {/*        {({input}) => (*/}
//                                     {/*            <Input*/}
//                                     {/*                {...input}*/}
//                                     {/*                className={'p-4 placeholder-black border border-gray-light'}*/}
//                                     {/*                placeholder={'Mobile Number'}*/}
//                                     {/*                disabled={!selected}*/}
//                                     {/*            />*/}
//                                     {/*        )}*/}
//                                     {/*    </Field>*/}
//                                     {/*</div>*/}
//                                     <div className={"mt-2"}>
//                                         <button
//                                             disabled={!submitSucceeded}
//                                             type="button" // Change to button if handled separately
//                                             onClick={() => {
//                                                 const {numberCode, number} = form.getState().values;
//
//                                                 handleTestSubmit();
//
//                                             }}
//                                             className={`rounded-full px-2 bg-blue-light border-1 ${edit ? "border-blue-primary text-blue-primary" : "border-gray-light text-gray-light"}`}
//                                         >
//                                             test SMS
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//
//                             {edit && (
//                                 <div className={"flex mt-3 gap-2"}>
//                                     <Button variant="outlined" onClick={() => {
//                                         form.reset();
//                                         setEdit(!edit);
//                                     }}
//                                             sx={{
//                                                 width: '100%',
//                                                 color: '#5000FF',
//                                                 borderColor: '#5000FF'
//
//                                             }}>Cancel</Button>
//
//                                     <FormSpy subscription={{dirty: true}}>
//                                         {({dirty}) => (
//
//                                             <Button disabled={!dirty} type={'submit'} variant="contained" sx={{
//                                                 color: 'white',
//                                                 width: '100%',
//                                                 backgroundColor: '#5000FF',
//                                                 '&:disabled': {
//                                                     color: '#191919',
//                                                 },
//                                             }}>Save</Button>
//
//                                         )}
//
//
//                                     </FormSpy>
//
//                                 </div>
//                             )}
//
//                         </div>
//                     </form>
//                 )}
//             />
//         </div>
//     </div>
//
// </main>
