import React, {useContext} from "react";
import { Box, MenuItem, Typography } from "@mui/material";
import CloseIcon                     from "@mui/icons-material/Close";
import { Field, Form }               from "react-final-form";
import { Select }                    from "mui-rff";
import Button                        from "@mui/material/Button";
import { LocalizationProvider }      from "@mui/x-date-pickers";
import { AdapterDayjs }              from "@mui/x-date-pickers/AdapterDayjs";
import api                           from "../../../Api";
import { PROCEDURES_PATH }           from "../../../NetService/doctorConstants";
import { useSnackbar }               from "notistack";
import { DateTimePicker }            from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs"
import {GlobalStateContext} from "../../../hooks/useGlobalSateDoctor";

type TableState = {
  name:string
  id: string;
  createdAt: string;
  product: string;
  qr: string;
  customer?:{
    firstName?: string;
    lastName?: string;
    phoneNumber: string;
    gender: string;
  };
  age: string;
  own: string;
  url: string;
  failed: string;
  duration: string;
  experience: string;
  procedure: string;
  problem: number;
  followup: string;
  same: string;
  procedureType: string;
  nextVisit?: string;
}
interface IsubData {
  date: {
    $d: Date;
  };
  procedureType: string;
  followup: string | boolean;
  problem: string
  sms?: boolean
  nextVisit: string | boolean;
}
interface EditProps {
  handelOpen: (param:any)=>void
  data: TableState;
  getData:()=>{}
}

export const EditProcedure = React.memo<EditProps>(({ handelOpen, data, getData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {selectedProduct} = useContext(GlobalStateContext);


  const onSubmit = async (values: IsubData) => {
    const testData = values.date?.$d.toString();
    const cleanedString = testData?.replace(/\([^)]+\)/, "");
    delete values.date;
    const newValues: IsubData = {
      ...values,
      followup: values.followup === "yes",
      nextVisit: cleanedString?.trim()
    };
    const url = `${PROCEDURES_PATH}/${data?.id}/updatereview`;
    try {
      await api.putApi(url, newValues,selectedProduct.identifier);
      enqueueSnackbar("Success", { variant: "success", hide: 100 } as any);
      getData();
      handelOpen(null);

    } catch (e) {
      enqueueSnackbar("Error", { variant: "error" });
    }
  };
  let initData = {
    procedureType: data?.procedureType,
    followup: data?.followup ? "yes" : "no",
    problem: data?.problem,

  };
  if(data.nextVisit){
    initData['date'] = dayjs(data.nextVisit)
  }
  return (
    <Box>
      <Box sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000
      }}>
        <Box sx={{
          padding: "16px",
          width: "466px",
          height: "425px",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          gap: "24px"
        }}>
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}>
            <Typography sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              textAlign: "left"
            }}>Patient information</Typography>
            <CloseIcon style={{ cursor: "pointer" }} onClick={() => handelOpen(null)}/>
          </Box>
          <Box sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            justifyContent: "space-between"
          }}>
            <Box display="flex" gap="24px">
              <Box display="flex" flexDirection={"column"} gap={"8px"}>
                <Box display="flex" alignItems="center">
                  <Typography sx={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "18px",
                    textAlign: "left",
                    color: "rgba(68, 74, 109, 1)",
                    minWidth: "70px"
                  }}>First name</Typography>
                  <Typography sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    textAlign: "left",
                    flex: 1

                  }}>{data?.name?.split(' ')[0] || '-'}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography sx={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "18px",
                    textAlign: "left",
                    color: "rgba(68, 74, 109, 1)",
                    minWidth: "70px"

                  }}>Phone</Typography>
                  <Typography sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    textAlign: "left",
                    flex: 1
                  }}>{data?.customer.phoneNumber}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography sx={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "18px",
                    textAlign: "left",
                    color: "rgba(68, 74, 109, 1)",
                    minWidth: "70px"

                  }}>Duration</Typography>
                  <Typography sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    textAlign: "left",
                    flex: 1
                  }}>{data?.duration}</Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection={"column"} gap={"8px"}>
                <Box display="flex" alignItems="center">
                  <Typography sx={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "18px",
                    textAlign: "left",
                    color: "rgba(68, 74, 109, 1)",
                    minWidth: "70px"

                  }}>Last name</Typography>
                  <Typography sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    textAlign: "left",
                    flex: 1
                  }}>{data?.name?.split(' ')[1] || '-'}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography sx={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "18px",
                    textAlign: "left",
                    minWidth: "70px",
                    color: "rgba(68, 74, 109, 1)"
                  }}>Gender</Typography>
                  <Typography sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    textAlign: "left",
                    flex: 1
                  }}>{data?.customer.gender}</Typography>
                </Box>

                {/*add time */}
                <Box display="flex" alignItems="center">
                  <Typography sx={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "18px",
                    textAlign: "left",
                    minWidth: "70px",
                    color: "rgba(68, 74, 109, 1)"
                  }}>Last visit</Typography>
                  <Typography sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    textAlign: "left",
                    flex: 1
                  }}>{new Date(data.nextVisit).toLocaleDateString("en-US")}</Typography>
                </Box>
              </Box>
            </Box>
            <hr
              style={{
                border: "1px solid rgba(230, 230, 230, 1)",
                margin: "20px 0"
              }}
            />
            <Form
              onSubmit={onSubmit}
              noValidate
              initialValues={initData || {}}
              render={({ handleSubmit, dirty, values }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Box sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px"
                    }}>
                      <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        width: "100%"
                      }}>
                        <Box width={"100%"} display="flex" flexDirection={"row"} gap={"8px"}>
                          <Select
                            name="procedureType"
                            label="Procedure type"
                            required={true}
                            sx={{
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#5000FF"
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#5000FF"
                              },
                              "&.Mui-focused .MuiInputLabel-root": {
                                color: "#5000FF !important",
                              },
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: "#5000FF !important",
                              }
                            }}

                          >
                            <MenuItem value="Composite Restorations">Composite
                              Restorations</MenuItem>
                            <MenuItem value="Crown Lengthening">Crown Lengthening</MenuItem>
                            <MenuItem value="Dental Implan">Dental Implan</MenuItem>
                            <MenuItem value=" Dental X-Rays"> Dental X-Rays</MenuItem>
                            <MenuItem value="Dental Cleanings">Dental Cleanings</MenuItem>
                            <MenuItem value="Dental Crown">Dental Crown</MenuItem>
                            <MenuItem value="Dental Examination">Dental
                              Examination</MenuItem>
                            <MenuItem value="Dental Extraction">Dental Extraction</MenuItem>
                            <MenuItem value="Dental Filling">Dental Filling</MenuItem>
                            <MenuItem value="Exam/ Checkup">Exam/ Checkup</MenuItem>
                            <MenuItem value="Extractions ">Extractions</MenuItem>
                            <MenuItem value="Fluoride Application">Fluoride
                              Application</MenuItem>
                            <MenuItem value="Orthodontics">Orthodontics</MenuItem>
                            <MenuItem value="Pocket Reduction">Pocket Reduction</MenuItem>
                            <MenuItem value="Prophylaxis">Prophylaxis</MenuItem>
                            <MenuItem value="Pulpotomy">Pulpotomy</MenuItem>
                            <MenuItem value="Root Canal ">Root Canal </MenuItem>
                            <MenuItem value="Silver Diamine Fluoride"> Silver Diamine
                              Fluoride</MenuItem>
                            <MenuItem value="Sealants">Sealants</MenuItem>
                            <MenuItem value="Space Maintainers">Space Maintainers</MenuItem>
                            <MenuItem value="Stainless Steel Crowns">Stainless Steel
                              Crowns</MenuItem>
                            <MenuItem value="Teeth Whitening">Teeth Whitening</MenuItem>
                            <MenuItem value="Tooth Extraction">Tooth Extraction</MenuItem>
                            <MenuItem value="Wisdom Teeth Removal">Wisdom Teeth
                              Removal</MenuItem>
                            <MenuItem value="X-Rays">X-Rays</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Select>
                          <Select
                            name="problem"
                            label="Problem level"
                            required={true}
                            sx={{
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#5000FF"
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#5000FF"
                              },
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: "#5000FF"
                              }
                            }}
                          >
                            <MenuItem value={1}>No problem</MenuItem>
                            <MenuItem value={2}>Mild</MenuItem>
                            <MenuItem value={3}>Moderate</MenuItem>
                            <MenuItem value={4}>Severe</MenuItem>
                            <MenuItem value={5}>Worst</MenuItem>
                          </Select>

                        </Box>
                        <Box display="flex" gap={"8px"}>
                          <Box width="50%">
                            <Select
                              name="followup"
                              label="Follow up visist"
                              required={true}
                              sx={{
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#5000FF"
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#5000FF"
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                  color: "#5000FF"
                                },
                                width: "100%"
                              }}
                            >
                              <MenuItem value="yes">Yes</MenuItem>
                              <MenuItem value="no">No</MenuItem>
                            </Select>
                          </Box>
                          <Box width="50%">
                            <Field name={"date"}>
                              {({ input, meta }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DateTimePicker
                                      label="Next Visit"
                                      sx={{ width: "100%",
                                        "&:hover .MuiOutlinedInput-notchedOutline": {
                                          borderColor: "#5000FF"
                                        },
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                          borderColor: "#5000FF"
                                        },
                                        "&.Mui-focused .MuiInputLabel-root": {
                                          color: "#5000FF !important",
                                        },
                                        "& .MuiInputLabel-root.Mui-focused": {
                                          color: "#5000FF !important",
                                        } }}
                                      value={input.value || null}
                                      onChange={(newValue) => {
                                        input.onChange(newValue || null);
                                      }}
                                  />
                                </LocalizationProvider>
                              )}
                            </Field>
                          </Box>

                        </Box>

                      </Box>

                      <Box sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end",
                        gap: "8px"
                      }}><Button variant="contained" size="medium" onClick={() => {
                        onSubmit({ ...values, sms: true });
                      }} sx={{
                        backgroundColor: "rgba(239, 235, 250, 1)",
                        borderRadius: "30px",
                        fontWeight: 500,
                        color: "black",
                        boxShadow: "none",
                        textTransform:'none'
                      }}>
                        Send SMS
                      </Button>
                        <Button variant="contained" size="medium" type={"submit"}
                                disabled={!dirty}
                                sx={{
                                  backgroundColor: "rgba(51, 55, 255, 1)",
                                  borderRadius: "30px",
                                  fontWeight: 500,
                                  boxShadow: "none",
                                  textTransform:'none'
                                }}>
                          Save Changes
                        </Button>
                      </Box>
                    </Box>
                  </form>
                );
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
});



