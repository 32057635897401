import React from "react";
import {Box, Typography} from "@mui/material";

interface CardProps {
    text?: string;
    number?: number;
}


export const StatCard = React.memo<CardProps>(({text, number}) => {
        return (
           <Box sx={{
               paddingY:'12px',
               paddingX:'12px',
               backgroundColor: 'white',
               display: 'flex',
               flexGrow:1,
               flexDirection: 'column',
               width : {
                   xs:'100%',
                   lg: '190px'
               }

           }}>
               <Box sx={{
                   width: '100%',
                   height: '100%',
                   display: 'flex',
                   flexDirection: 'column',
                   flexGrow: 1,
                   justifyContent: 'space-between',
               }}>
                   <Typography
                       align="center"
                       sx={{
                           fontFamily: 'Poppins',
                           fontSize: '14px',
                           fontWeight: 500,
                           lineHeight: '24px',
                       }}
                   >
                       {text}
                   </Typography>

                   <Typography
                       align="center"
                       sx={{
                           fontFamily: 'Poppins',
                           fontSize: '32px',
                           fontWeight: 600,
                           lineHeight: '48px',
                           color: '#3337FF',
                       }}
                   >
                       {!isNaN(number) ? number : '0'}
                   </Typography>
               </Box>
           </Box>
        )
    })