import { Box, IconButton, Menu, MenuItem, Tooltip }           from "@mui/material";
import React, {useContext, useEffect, useMemo} from "react";
import TextField                                              from "@mui/material/TextField";
import InputAdornment                                         from "@mui/material/InputAdornment";
import SearchIcon                                             from "@mui/icons-material/Search";
import FilterAltIcon                                          from "@mui/icons-material/FilterAlt";
import ArrowDropDownIcon                                      from "@mui/icons-material/ArrowDropDown";
import api                                                    from "../../../Api";
import { STATISTICS_PATH }                                    from "../../../NetService/doctorConstants";
import { Tables }                                             from "../../Table";
import EditNoteIcon                                           from "@mui/icons-material/EditNote";
import { GlobalStateContext }                                 from "../../../hooks/useGlobalSateDoctor";
import { EditProcedure }                                      from "../../PopUps/EditProcedure";
import ArrowBackIosIcon                                       from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon                                    from "@mui/icons-material/ArrowForwardIos";
import Button                                                 from "@mui/material/Button";
import {format} from "date-fns";

type TableState = {
  id: string
  createdAt: string;
  product: string;
  qr: string;
  name: string;
  age: string;
  gender: string;
  phoneNumber: string;
  own: string;
  url: string;
  failed: string;
  duration: string;
  experience: string;
  procedure: string;
  problem: number;
  followup: string;
  same: string;
  procedureType: string;
}

const filterMap = {
  'All' : -1,
  'Today': 0,
  'Yesterday': 1,
  'Last 7 days': 7,
  'Last 14 days': 14
}


export const Patients = React.memo(() => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [tableData, setTableData] = React.useState<TableState[]>([]);
  const [search, setSearch] = React.useState("");
  const [filterName, setFilterName] = React.useState<string>("All");
  const { selectedProduct,filterUrl } = useContext(GlobalStateContext);
  const [isOpen, setIsOpen] = React.useState<TableState | null>();
  const [page, setPage] = React.useState(1);
  const limit = 10

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  async function getData() {
    try {
      let url =  filterUrl ? `${filterUrl}&${cratingQuery}` : `${STATISTICS_PATH}?${cratingQuery}`
      console.log(url)
      const result = await api.getApi(url, selectedProduct.identifier);
      if (result.results.length) {
        setTableData(result.results);
      }else {
        setTableData([])
      }
    } catch (e) {
      console.log(e);
    }
  }

  const cratingQuery = useMemo(()=>{
    let where = {}
    let query = `skip=${(page -1) * limit }&limit=${page * limit}&include=user,customer&count=true`
    if(filterMap[filterName] !== -1 ){
      const today = new Date();
      today.setHours(23, 59, 59, 999);
      let endDate = new Date();
      endDate.setHours(0, 0, 0, 0);
      endDate.setDate(today.getDate() - filterMap[filterName]);
      where = {
        createdAt: {
          "$gt": endDate.toISOString(),
          "$lt": today.toISOString()
        }
      };
    }
    if(search){
      where = {
        ...where,
        'name':{$regex: search, $options:"i"}
      }
    }
    if(Object.keys(where).length){
      query = query + "&where=" + JSON.stringify(where)
    }
    return query;
  },[page,filterName,search]);

  const handleClose = (filter?) => {
    setAnchorEl(null);
    if(filter){
      setFilterName(filter);
    }
  };
  useEffect(() => {
    setFilterName('All')
    setPage(1)
    setSearch('')
    getData()
  }, [selectedProduct.id]);

  useEffect(() => {
    getData()
  }, [cratingQuery]);

  const headers = [
    {
      field: "name",
      headStyle: {
        width: "150px"
      },
      renderCell: ({ row }) => {
        const customerName = row.name || '-'
        return <Box>{customerName}</Box>;
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerName: "Name",
      headerClassName: "table-header"
    },
    {
      field: "createdAt",
      headerName: "Created",
      renderCell: ({ row }) => {
        return (<Box>
          {format(new Date(row.updatedAt), "dd.MM.yyyy")}
        </Box>);
      },
      headStyle: {
        width: "150px"
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    },
    {
      field: "qr",
      headerName: "QR Scan",
      renderCell: (params) => {
        return (
          <Box>
            {params.row.qr ? params.row.qr : "false"}
          </Box>
        );
      },
      headStyle: {
        width: "90px"
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    },
    {
      field: "age",
      headerName: "Age",
      renderCell: (params: any) => {
        return (
          <Box>
            {params?.row?.customer?.age}
          </Box>
        );
      },
      headStyle: {
        width: "90px"
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"

    },
    {
      field: "own",
      headerName: "Own",
      headStyle: {
        width: "90px"

      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    },
    {
      field: "url",
      headerName: "Media",
      headStyle: {
        width: "20%"
      },
      cellStyle: {
        width: "120px",
        whiteSpace: "nowrap"
      },
      renderCell: (param: any) => {
        return (
          <a href={param.value} target={"_blank"} style={{
            color: "rgba(51, 55, 255, 1)"
          }}>{param.value}</a>
        );
      },
      headerClassName: "table-header"
    },
    {
      field: "failed",
      headerName: "Failed",
      headStyle: {
        width: "90px"
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    },
    {
      field: "experience",
      headerName: "Experience",
      headStyle: {
        width: "90px"
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    },
    {
      field: "procedureType",
      headerName: "Procedure",
      headStyle: {
        width: "90px"
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    },
    {
      field: "problem",
      headerName: "Problem",
      headStyle: {
        width: "90px",
        textAlign: "center"

      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    },
    {
      field: "followup",
      headerName: "Follow Up",
      headStyle: {

        whiteSpace: "break-spaces",
        width: "90px"
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    },
    {
      field: "same",
      headerName: "Same",
      headStyle: {
        width: "90px"
      },
      cellStyle: {
        whiteSpace: "nowrap"
      },
      headerClassName: "table-header"
    }

  ];
  const editHeader = [
    {
      field: "actions",
      headerName: "Actions",
      cellStyle: {
        position: "absolute",
        right: "0px"
      },
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "table-header",
      renderCell: (params: any) => {
        const onEdit = (e: any) => {
          e.stopPropagation();
          setIsOpen(params.value);
        };
        return (
          <Box display={"flex"}>
            <Tooltip title={"Edit"} arrow>
              <IconButton disableFocusRipple={true} onClick={onEdit} sx={{ color: "#444A6D" }}>
                <EditNoteIcon/>
              </IconButton>
            </Tooltip>
          </Box>
        );
      }
    }
  ];
  const handelSearch = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    }
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: "16px"
    }}>
      <Box sx={{
        backgroundColor: "white",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "16px"

      }}>
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-center"

        }}>
          <TextField
            margin="none"
            fullWidth
            name="search"
            placeholder="Search names"
            onChange={handelSearch}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#444A6D" }}/>
                  </InputAdornment>

                )
              }
            }}
            sx={{

              "&::placeholder": {
                color: "#444A6D",
                fontWeight: "400",
                fontsize: "16px",
                lineHeight: "24px"
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(13, 17, 242, 1)"
              },

              width: "210px",
              height: "56px",
              paddingTop: 0
            }}

          />
          <Box sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px"
          }}>
            <FilterAltIcon sx={{ color: "#444A6D", width: "16px", height: "16px" }}/>
            <p style={{ cursor: "pointer" }} onClick={handleClick}>Filter by: {filterName}</p>
            <Box>
              <Box sx={{ cursor: "pointer" }} onClick={handleClick}>
                   <ArrowDropDownIcon/>
              </Box>
              <Menu
                id="composition-button"
                aria-labelledby="composition-button"
                anchorEl={anchorEl}
                open={open}
                onClose={()=>handleClose()}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
              >
                <MenuItem onClick={() => {handleClose('All')}}>All</MenuItem>
                <MenuItem onClick={() => {handleClose('Today')}}>Today</MenuItem>
                <MenuItem onClick={() => {handleClose('Yesterday')}}>Yesterday</MenuItem>
                <MenuItem onClick={() => {handleClose('Last 7 days')}}>Last 7 days</MenuItem>
                <MenuItem onClick={() => {handleClose('Last 14 days')}}>Last 14 days</MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
        <Box>
          {isOpen && <EditProcedure handelOpen={setIsOpen} data={isOpen} getData={getData}/>}
          {tableData && (
            <Box>
              <Tables headers={headers} rows={tableData} editHeader={editHeader}/>
              <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: "16px"
              }}>
                <Box>
                  <Button disabled={!(tableData && page > 1)} onClick={() => setPage((prev) => prev - 1)}>
                    <ArrowBackIosIcon/>
                  </Button>
                </Box>
                <Box>
                  <Button disabled={!(tableData && tableData.length === 10)}
                          onClick={() => setPage((prev) => prev + 1)}>
                    <ArrowForwardIosIcon/>
                  </Button>
                </Box>
              </Box>

            </Box>)}
        </Box>
      </Box>
    </Box>
  );
});