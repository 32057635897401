import React from 'react';
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';

export interface TableColumn {
    field?: string;
    headerName?: string;
    width?: number | string;
    cellStyle?: any;
    headStyle?: any;
    renderCell?: (row: any) => React.ReactNode;
}

export interface TableProps {
    headers: TableColumn[];
    editHeader?: TableColumn[];
    rows: any[];
}

export const Tables = React.memo<TableProps>(({ headers, rows, editHeader }) => {
    return (
        <Box sx={{ width: '100%' }}>
            <Sheet
                variant="outlined"
                sx={{
                    '--TableRow-stripeBackground': 'rgb(255, 255, 255)',
                    '--Table-lastColumnWidth': '100px',
                }}
            >
                <Box
                    sx={{
                        borderRadius: '4px',
                        overflow: 'auto',
                    }}
                >
                    <Table

                        stripe="odd"
                        sx={{
                            backgroundColor: 'white',
                            minWidth: '800px',
                            '& tr > *:last-child': {
                                position: 'sticky',
                                right: 0,
                                bgcolor: 'var(--TableCell-headBackground)',
                            },

                            '& th':{
                                padding: '16px',
                                fontFamily: 'Poppins',
                                backgroundColor: '#F1F1F4 !important',
                                textAlign:'center',
                            },
                            '& td': {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                padding: '16px',
                                fontFamily: 'Poppins',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '24px',
                                textAlign: 'center',
                            },


                        }}
                    >
                        <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th
                                    key={index}
                                    style={{
                                        ...(header.headStyle || {}),
                                        position: header.field === 'actions' ? 'sticky' : 'static',
                                        top: 0,
                                        backgroundColor: 'white',
                                        // zIndex: 1,
                                    }}
                                >
                                    {header.headerName}
                                </th>
                            ))}
                            <th
                                style={{
                                    position: 'sticky',
                                    top: 0,
                                    right: 0,
                                    backgroundColor: 'white',
                                    // zIndex: 1,
                                    width: 'var(--Table-lastColumnWidth)',
                                }}
                            >
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {headers.map((header, index) => (

                                    <td key={index} align="center" style={{...(header.cellStyle || {})}}>
                                        {(header.renderCell)
                                            ? header.renderCell({value: row[header.field], row})
                                            : `${row[header.field]}` || '-'}
                                    </td>

                                ))}

                                <td style={{backgroundColor: 'white'}}>
                                    {editHeader[0].renderCell({value: row})}
                                </td>

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Box>
            </Sheet>
        </Box>
    );
});
