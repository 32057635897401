import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Tables }                                                       from "../../Table";
import EditNoteIcon                                                     from "@mui/icons-material/EditNote";
import api                                                              from "../../../Api";
import { PRODUCTS_PATH, REFERRALS_PATH, VIDEOS_PATH }                   from "../../../NetService/doctorConstants";
import { useSnackbar }                                                  from "notistack";
import TextField                                                        from "@mui/material/TextField";
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography }         from "@mui/material";
import { MediaCardDoctor }                                              from "../../MediaCardDoctor";
import DeleteForeverOutlinedIcon
                                                                        from "@mui/icons-material/DeleteForeverOutlined";
import InputAdornment                                                   from "@mui/material/InputAdornment";
import SearchIcon                                                       from "@mui/icons-material/Search";
import FilterAltIcon                                                    from "@mui/icons-material/FilterAlt";
import ArrowDropDownIcon                                                from "@mui/icons-material/ArrowDropDown";
import AddIcon                                                          from "@mui/icons-material/Add";
import { AddVideo }                                                     from "../../PopUps/AddVideo";
import { DelConfirm }                                                   from "../../PopUps/DelConfirm";
import { GlobalStateContext }                                           from "../../../hooks/useGlobalSateDoctor";
import ArrowBackIosIcon                                                 from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon                                              from "@mui/icons-material/ArrowForwardIos";
import Button from "@mui/material/Button";

interface VideoData {
  age: ("3-5" | "6-12" | "13-18" | "19-35" | "36+")[]; // Define possible age categories
  category: "Cartoon" | "Nature" | "360VR" | "Game" | "Other"; // Define possible categories
  channelTitle: string;
  createdAt: string;
  id: string;
  image: string;
  title: string;
  updatedAt: string;
  url: string;
  youtubeId: string
}

export const ContentList = React.memo(() => {
    const [data, setData] = React.useState<VideoData[]>([]);
    const [tableData, setTableData] = React.useState<VideoData[]>([]);
    const [edit, setEdit] = React.useState<VideoData | undefined>();
    const [isOpen, setIsOpen] = useState(false);
    const [filteredName, setFilteredName] = React.useState("All");
    const [search, setSearch] = React.useState("");
    const [isOpenDelete, setIsOpenDelete] = React.useState();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { selectedProduct } = useContext(GlobalStateContext);
    const [page, setPage] = React.useState(1);
    const { enqueueSnackbar } = useSnackbar();

    const fetchData = async (query?: string) => {
      let url = `${PRODUCTS_PATH}/${selectedProduct.id}/videos`
      if(query){
        url+=`?${query}`
      }
      try {
        const result = await api.getApi(url, selectedProduct.identifier);
        setData(result.results);

      } catch (error) {
        console.error("Error", error);
      }
    };
    useEffect(() => {
      if (selectedProduct.id) {
        fetchData();
      }
    }, [selectedProduct.id]);



    //menu

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
      const value =  e.currentTarget.getAttribute("value")
      if ( value  &&  value!== "All" ) {
        const age = e.currentTarget.getAttribute("value");
        const filtered = `where={"age":"${age}"}`;
        setFilteredName(age)
        fetchData(filtered);
      }else{
        setFilteredName("All")
        fetchData()
      }
      setAnchorEl(null);
    };


    //pagination
  useEffect(() => {
    const end = page * 10
    const start = end - 10
    setTableData(data.slice(start, end));
  }, [page,data]);


    const deleteVideo = useCallback(async (id) => {
      try {
        const url = `${VIDEOS_PATH}/${id}`;
        await api.deleteApi(url, undefined, selectedProduct.identifier);
        enqueueSnackbar("Success", { variant: "success" });
        fetchData();
      } catch (e) {
        console.log(e);
        enqueueSnackbar("Error", { variant: "error" });
      }

    }, []);

    const handleIsOpen = (value, id?) => {
      setIsOpenDelete(value);
      if (id) {
        deleteVideo(id);
      }
    };

    //AddVideoPopUp
    const handleOpen = useCallback((open, update?) => {
      setIsOpen(open);
      if (update) {
        fetchData();
      }
    }, []);

    let debounceTimeout;
    const handelSearch = (e) => {
      const searchValue = e.target.value;

      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
        setSearch(searchValue);
      }, 1000);
    };

    const headers = [
      {
        field: "age",
        headerName: "Age range",
        width: "10%",

        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        },
        headerClassName: "table-header",
        renderCell: (param: any) => {
          if (param.row.age.length > 1) {
            return param.row.age.join(", ");
          } else {
            return param.row.age;
          }
        }
      },
      {
        field: "url",
        headerName: "Video",
        width: "30%",
        height: 20,
        headerClassName: "table-header",
        renderCell: (param: any) => {
          const url = param.value;
          return (
            <div style={{
              display: "flex",
              color: "#3337FF",
              alignItems: "center"
            }}>
              <img style={{ width: "64px", height: "38px", marginRight: "5px", borderRadius: "8px" }}
                   src={param.row.image} alt={param.row.title}/>
              <a className={"truncate"} href={url} target={"_blank"} rel={"noreferrer"}>{url}</a>
            </div>
          );
        }
      },
      {
        field: "category",
        headerName: "Category",
        width: "10%",
        headerClassName: "table-header"
      },
      {
        field: "title",
        headerName: "Name",
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        },
        width: "100%",
        headerClassName: "table-header"
      }
    ];

    const editHeader = [
      {
        field: "actions",
        headerName: "Actions",
        cellStyle: {
          position: "absolute",
          right: "0px"
        },
        width: 80,
        sortable: false,
        disableColumnMenu: true,
        headerClassName: "table-header",
        renderCell: (params: any) => {
          const onEdit = () => {
            handleOpen(true);
            setEdit(params);
          };
          return params.value.product ? (
            <Box display="flex">
              <Tooltip title="Edit" arrow>
                <IconButton
                  disableFocusRipple
                  onClick={onEdit}
                  sx={{ color: "#444A6D" }}
                  color="primary"
                >
                  <EditNoteIcon/>
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete" arrow onClick={() => handleIsOpen(params.value.id)}>
                <IconButton disableFocusRipple sx={{ color: "#444A6D" }}>
                  <DeleteForeverOutlinedIcon/>
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <></>
          );
        }
      }
    ];

    let videosCount = useMemo(() => {
      let count = {
        "3-5": { Cartoon: 0, Nature: 0, "360VR": 0, Game: 0, Other: 0, Relax: 0, Program: 0 },
        "6-12": { Cartoon: 0, Nature: 0, "360VR": 0, Game: 0, Other: 0, Relax: 0, Program: 0 },
        "13-18": { Cartoon: 0, Nature: 0, "360VR": 0, Game: 0, Other: 0, Relax: 0, Program: 0 },
        "19-35": { Cartoon: 0, Nature: 0, "360VR": 0, Game: 0, Other: 0, Relax: 0, Program: 0 },
        "36+": { Cartoon: 0, Nature: 0, "360VR": 0, Game: 0, Other: 0, Relax: 0, Program: 0 }
      };
      if (data.length) {
        data.forEach((item: VideoData) => {
          item.age.forEach((age) => {
            count[ age ][ item.category ] = count[ age ][ item.category ] + 1;
          });
        });
      }
      return count;
    }, [data]);
    return (
      <>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          padding: "16px",
          gap: "16px"
        }}>
          <MediaCardDoctor videoData={videosCount}/>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            padding: "16px",
            gap: "16px",
            backgroundColor: "white"
          }}>
            <Box sx={{
              display: "flex",
              justifyContent: "space-between"
            }}>
              <Typography sx={
                {
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px"
                }
              }>Added Videos</Typography>
              <Box display="flex">
                <Typography sx={{
                  fontFamily: "Poppins",
                  cursor: "pointer",
                  color: "rgba(51, 55, 255, 1)",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "24px"
                }} onClick={() => {
                  handleOpen(true)
                  setEdit(undefined)}} >Add New Video </Typography>
                <Typography><AddIcon sx={{
                  cursor: "pointer",
                  width: "16px",
                  height: "16px",
                  color: "rgba(51, 55, 255, 1)"
                }}/></Typography>
              </Box>
              {isOpen && <AddVideo isOpen={handleOpen} video={edit}/>}

            </Box>
            <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-center"
            }}>
              {/*findeme*/}
              <TextField
                margin="none"
                fullWidth
                name="search"
                placeholder="Search title"
                onChange={handelSearch}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#444A6D" }}/>
                      </InputAdornment>

                    )
                  }
                }}
                sx={{

                  "&::placeholder": {
                    color: "rgba(68, 74, 109, 1)",
                    fontWeight: "400",
                    fontsize: "16px",
                    lineHeight: "24px"
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(13, 17, 242, 1)"
                  },

                  width: "210px",
                  height: "56px",
                  paddingTop: 0
                }}

              />
              <Box sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px"

              }}>
                <FilterAltIcon sx={{ color: "#444A6D", width: "16px", height: "16px" }}/>
                <Typography sx={{ fontFamily: "Poppins" }}>Filter by: {filteredName}</Typography>
                <Box>

                  <Box sx={{ cursor: "pointer" }} onClick={handleClick}>
                    <ArrowDropDownIcon/>
                  </Box>
                  <Menu
                    id="composition-button"
                    aria-labelledby="composition-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left"
                    }}
                  >
                    <MenuItem value={"All"} onClick={handleClose}>All</MenuItem>
                    <MenuItem value={"3-5"} onClick={handleClose}>3-5</MenuItem>
                    <MenuItem value={"6-12"} onClick={handleClose}>6-12</MenuItem>
                    <MenuItem value={"13-18"} onClick={handleClose}>13-18</MenuItem>
                    <MenuItem value={"19-35"} onClick={handleClose}>19-35</MenuItem>
                    <MenuItem value={"36+"} onClick={handleClose}>36+</MenuItem>
                  </Menu>
                </Box>
              </Box>
            </Box>
            <Box>
              {isOpenDelete && (<DelConfirm id={isOpenDelete} isOpen={handleIsOpen}/>)}
              <Tables headers={headers} rows={tableData.filter((value) => {
                if (search) {
                  const regex = new RegExp(`(?<=^|.)${search}(?=$|.)`, "i");
                  if (regex.test(value.title)) {
                    return value;
                  }
                } else {
                  return value;
                }
              })}
                 editHeader={editHeader}/>
              <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: "16px"
              }}>
                <Box>
                  <Button disabled={!(tableData &&  page > 1)} onClick={() => setPage((prev) => prev - 1)}>
                    <ArrowBackIosIcon/>
                  </Button>
                </Box>
                <Box>
                  <Button disabled={tableData.length <10}
                          onClick={() => setPage((prev) => prev + 1)}>
                    <ArrowForwardIosIcon/>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

      </>
    );
  }
);