import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { AuthProviderDoctor }              from "../../hooks/useGlobalSateDoctor";
import { Statistics }                      from "../DoctorPage/Statistics";
import { SnackbarProvider }                from "notistack";
import { LeftBar }                         from "../LeftBar";
import React, { useContext }               from "react";
import { AuthContext }                     from "../../context/Authprovider";
import { LogIn }                           from "../DoctorPage/DoctorLogin/LogIn";
import { SmsConfig }                       from "../DoctorPage/smsConfig";
import { Referral }                        from "../DoctorPage/Referral";
import { ContentList }                     from "../DoctorPage/Contetnt";
import { ContactUs }                       from "../DoctorPage/contactUs";
import { TopBar }                          from "../topBar";
import {Box, Typography} from "@mui/material";
import { BottomBar }                       from "../bottomBar";
import { Patients }                        from "../DoctorPage/Patients";
import api                                 from "../../Api";
import { SUBSCRIPTION }                    from "../../NetService/doctorConstants";
import { GlobalStateContext }              from "../../hooks/useGlobalSateDoctor";

function PageWrapper() {
  const { selectedProduct, setSelectedProduct, stateGlobal } = useContext(GlobalStateContext);
  return (
    <Box sx={{
      backgroundColor: "rgba(241, 241, 244, 1)",
      height: "100vh",
    }}>
      <Box sx={{
        display: {
          xs: "flex",
          sm: "none",
        },
        padding: "16px",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
      }}>
        <img style={{
          width: "389px",
          height: "243px",
        }} src={'../Group.webp'} />
        <Typography sx={{
          fontFamily: 'Poppins',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '24px',
          textAlign: 'center'
        }}>
          The device is too small to show the website correctly.
          Please rotate it or try with a bigger device.
        </Typography>

      </Box>
      <Box sx={{
        display: {
          xs:'none',
          sm: 'block',

          lg: "none"
        },
        height: "100vh"
      }}>
        <TopBar/>
        <Box sx={{
          position: "relative",
          display: {
            xs: "block",
            sm: "block",
            lg: "none"
          }
        }}>
          <Box overflow={"scroll"} paddingBottom={"50px"}>
            <Outlet/>
          </Box>
        </Box>
        <BottomBar/>
      </Box>
      <Box sx={{
        width: "100%",
        display: {
          xs: "none",
          sm: "none",
          lg: "flex"
        }
      }}>
        <LeftBar/>
        <Box width={"100%"} marginLeft={"204px"}>
          <TopBar/>
          <Box overflow={"scroll"}>
            <Outlet/>
          </Box>
        </Box>
      </Box>
      {/*need to add buttom bar and change css 1200 ic nerqev */}
    </Box>
);
}

function DoctorRouter({ isLoggedIn }) {
  return (
    <AuthProviderDoctor>
      <SnackbarProvider maxSnack={3}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right"
                        }}>
        <Routes>
          {!isLoggedIn ? (
            <>
              <Route path="logIn" element={<LogIn/>}/>
              <Route path="*" element={<Navigate replace to="logIn"/>}/>
            </>
          ) : (
            <Route path="*" element={<PageWrapper/>}>
              <Route path="statistics/*" element={<Statistics/>}/>
              <Route path="sms-conf/*" element={<SmsConfig/>}/>
              <Route path="content/*" element={<ContentList/>}/>
              <Route path="ref/*" element={<Referral/>}/>
              <Route path="contactus/*" element={<ContactUs/>}/>
              <Route path="patients/*" element={<Patients/>}/>
              <Route path="*" element={<Navigate replace to="statistics"/>}/>
            </Route>
          )}
        </Routes>
      </SnackbarProvider>
    </AuthProviderDoctor>
  );
}

export default DoctorRouter;

