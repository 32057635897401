import React, {useContext, useEffect, useState} from 'react'
import {Card} from "../../Card";
import {Box, MenuItem, Typography} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import countries from "../../CountrySelectorWithDetection/countries.json";
import {Select, TextField} from "mui-rff";
import {Field, Form} from "react-final-form";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import api from "../../../Api";
import { PRODUCTS_PATH, } from "../../../NetService/doctorConstants";
import {useSnackbar} from "notistack";
import {GlobalStateContext} from "../../../hooks/useGlobalSateDoctor";

type Feedback = {
   [key : string]:{
         id: string,
        feedback: string;
        enabled: any;
        link: any;
        reviewPlatform: string;
        name?: string;
       identifier?: string;
       phoneNumber: string

   }
};

export const SmsConfig = React.memo(() => {
    const [state, setState] = React.useState<Feedback>();
    const [card,setCard] = React.useState<Feedback | null>(null);
    const {enqueueSnackbar} = useSnackbar();
    const { stateGlobal } = useContext(GlobalStateContext);
    const [selectedCountry, setSelectedCountry] = useState<any>({
        "name": "United States",
        "dialCode": "+1",
        "isoCode": "US",
        "flag": "https://cdn.kcak11.com/CountryFlags/countries/us.svg"
    });

    const handleChange = (country: any) => {
        setSelectedCountry(country);
    };
    useEffect(()=>{
        async function getFeedback() {
            try {
                const feedback = await api.getApi(`${PRODUCTS_PATH}/relatedProducts?include=user`)
                const newFeedback = {};

                feedback.forEach((feedbackTemplate) => {
                    const isMain = feedbackTemplate.activationKey === stateGlobal.activationKey
                    if(isMain){
                        newFeedback[feedbackTemplate.activationKey] = {
                            phoneNumber: feedbackTemplate.user.phoneNumber,
                            identifier:'Main',
                            id: feedbackTemplate.id,
                            feedback: feedbackTemplate.feedbackTemplate.feedback,
                            enabled: feedbackTemplate.feedbackTemplate.enabled,
                            link: feedbackTemplate.feedbackTemplate.google || feedbackTemplate.feedbackTemplate.yelp,
                            reviewPlatform: feedbackTemplate.feedbackTemplate.google
                                ? "google"
                                : feedbackTemplate.feedbackTemplate.yelp
                                    ? "yelp"
                                    : "",
                            }
                    }else{
                       const template =  stateGlobal?.relatedProducts.find(r => r.key === feedbackTemplate.activationKey)
                        newFeedback[feedbackTemplate.activationKey] = {
                            phoneNumber: feedbackTemplate.user.phoneNumber,
                            identifier: template.identifier ,
                            id: feedbackTemplate.id,
                            feedback: feedbackTemplate.feedbackTemplate.feedback,
                            enabled: feedbackTemplate.feedbackTemplate.enabled,
                            link: feedbackTemplate.feedbackTemplate.google || feedbackTemplate.feedbackTemplate.yelp,
                            reviewPlatform: feedbackTemplate.feedbackTemplate.google
                                ? "google"
                                : feedbackTemplate.feedbackTemplate.yelp
                                    ? "yelp"
                                    : "",
                        }
                    }
                })
                setState(newFeedback);
            } catch (e) {
                console.log(e);
            }
        }
        getFeedback();
    },[stateGlobal])

    const handleSave = async (values) =>{
        const keysList = Object.keys(values)
        const result = keysList.map((key) => ({
            activationKey: key,
            feedbackTemplate: {
                enabled: values[key]?.enabled || false,
                feedback: values[key]?.feedback || false,
                'google': values[key]?.reviewPlatform === 'google' ? values[key]?.link : '',
                'yelp': values[key]?.reviewPlatform === 'yelp' ? values[key]?.link : '',
            },
        }));
        const sendingData = {"templates":result}
        try {
            await api.putApi(`${PRODUCTS_PATH}/update/templates`, sendingData);
            enqueueSnackbar("Success", {variant: "success"})
        }catch (e){
            console.error(e);
            enqueueSnackbar("Error", {variant: "error"})
        }
        setCard(values)
    }

    const handleSms = async (value,form) => {
        const phoneRegex = /^[\+][0-9]{8,}$/;

        if (!phoneRegex.test(value.phoneNumber)) {
            enqueueSnackbar("Invalid phone number format.", { variant: "error" });
            return { phoneNumber: "Invalid phone number format." };
        }
        try{
            const resulte = await api.postApi(`${PRODUCTS_PATH}/feedback/customTest`,value);
            enqueueSnackbar("submitted successfully!", { variant: "success" });
        }catch (e){
            console.error(e);
            enqueueSnackbar("Invalid phone number format.", { variant: "error" });

        }
        form.reset()
    }
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            width: '100%',
            height: '100%',
        }}>

            <Box sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                padding: '16px',
                width:'100%'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}>
                    <Typography style={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontWeight: 500,
                        lineHeight: '24px',
                        textAlign: 'left',
                    }}>Message Testing</Typography>
                    <Typography style={{
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '18px',
                        textAlign: 'left'
                    }}>
                        You can test your set up message by inputting a custom phone number or by the system number.
                    </Typography>

                    <Form  onSubmit={handleSms}
                          initialValues={{
                              phoneNumber: stateGlobal?.user?.phoneNumber || selectedCountry.dialCode,
                              title : stateGlobal?.id
                          }}
                          render={({handleSubmit,form}) => (
                              <form>
                                <Box display="flex"  gap="16px" >
                                        <Box>
                                            <Field name="title" type="select">
                                                {({ input }) => (
                                                    <Select
                                                        required={true}
                                                        sx={{
                                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#5000FF",
                                                            },
                                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: "#5000FF",
                                                            },
                                                            "& .MuiInputLabel-root.Mui-focused": {
                                                                color: "#5000FF",
                                                            },
                                                            width: "264px",
                                                        }}
                                                        {...input}
                                                        label="Select SMS Service"
                                                    >
                                                        {state && typeof state === "object" && Object.keys(state).length > 0 ? (
                                                            Object.keys(state).map((key, index) => {
                                                                return(  <MenuItem key={index} onClick={()=>{
                                                                    form.change('phoneNumber', state[key].phoneNumber);
                                                                }} value={state[key].id}>
                                                                            {state?.[key]?.identifier || key}
                                                                         </MenuItem>)
                                                            })
                                                        ) : (
                                                            <MenuItem disabled>No options available</MenuItem>
                                                        )}
                                                    </Select>
                                                )}
                                            </Field>
                                        </Box>
                                    <Box sx={{display: 'flex', gap: '16px', alignItems: 'center'}}>
                                        <Field name={'phoneNumber'}>

                                            {({input}) => (
                                                <TextField
                                                    {...input}
                                                    sx={{
                                                        width: '264px',
                                                        '& .MuiInputBase-root': {
                                                            paddingLeft: '0px',
                                                            marginRight: '0px',
                                                        },
                                                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#5000FF',
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: '#5000FF',
                                                        },
                                                    }}

                                                    margin="none"
                                                    type={'tel'}
                                                    name="phoneNumber"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Box>
                                                                    <FormControl sx={{
                                                                        m: 1, maxWidth: "56px",
                                                                    }}>
                                                                        <Select
                                                                            sx={{
                                                                                width: '56px',
                                                                                '& .MuiSelect-select': {
                                                                                    paddingLeft: '0px',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                },
                                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                                    border: 'none',

                                                                                },
                                                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                                    border: 'none',
                                                                                },
                                                                            }}
                                                                            name="phoneNumber2"
                                                                            value={selectedCountry.dialCode}
                                                                            autoWidth
                                                                            renderValue={() => {
                                                                                const country = countries.find((item) => {
                                                                                    const regex = new RegExp(`^${item.dialCode.replace(/\+/g, "\\+")}`, "i");
                                                                                    return regex.test(input.value);
                                                                                });
                                                                                return (
                                                                                    <Box sx={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        gap: '8px'
                                                                                    }}>
                                                                                        <img
                                                                                            src={country?.flag}
                                                                                            alt={country?.name}
                                                                                            style={{
                                                                                                width: '24',
                                                                                                height: '18px'
                                                                                            }}
                                                                                        />
                                                                                    </Box>
                                                                                );
                                                                            }}
                                                                        >
                                                                            <Box sx={{
                                                                                maxHeight: '100px',
                                                                                overflow: 'scroll'
                                                                            }}>
                                                                                {countries.map((country) => (

                                                                                    <MenuItem key={country.name}
                                                                                              value={`${country.dialCode}`}
                                                                                              onClick={() => handleChange(
                                                                                                  country
                                                                                              )}>

                                                                                        <Box sx={{
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            gap: '8px'
                                                                                        }}>
                                                                                            <img
                                                                                                src={country.flag}
                                                                                                alt={country.name}
                                                                                                style={{
                                                                                                    width: 24,
                                                                                                    height: 16
                                                                                                }}
                                                                                            />
                                                                                            {country.dialCode}
                                                                                        </Box>
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Box>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Box>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        {/*Test with Custom Number*/}
                                        <label>
                                            <Typography onClick={card ? handleSubmit : undefined} style={{
                                                fontFamily: 'Poppins',
                                                fontWeight: 500,
                                                color: card ? '#3337FF': '#E6E6E6',
                                                cursor:  card ? 'pointer' : null,
                                            }}>Test with Custom Number</Typography>
                                        </label>

                                    </Box>
                                </Box>
                              </form>
                          )}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            gap: '16px',
                            minWidth: '600px',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Form onSubmit={handleSave}
                              initialValues={ state }
                            render={({handleSubmit,dirty, values,form}) => (
                                <form onSubmit={handleSubmit} style={{width:'100%'}}>
                                    <Box sx={{
                                        width: '100%',
                                        display: 'flex',
                                        gap: '16px',
                                        minWidth: '600px',
                                        flexWrap: 'wrap',
                                        position:'relative'
                                    }}>

                                        { state ? Object.keys(state).map((item, index) => (
                                            <Box key={index} >
                                                <Card form={form} aKey={item} surname={`${state?.[item].identifier}`} data={values} />
                                            </Box>
                                        )):null}
                                        <Box sx={{
                                            backgroundColor: 'white',
                                           position: 'fixed',
                                            bottom: {
                                                sm: '80px',
                                                lg: '0px'
                                            },
                                            width:{
                                                sm:'calc(100% - 31px)',
                                                lg: '100%'
                                                // lg: 'calc(100% - 236px)'
                                            },

                                            marginLeft:{
                                                sm: '16px',
                                                lg: "0px"
                                                // lg: "220px"
                                            },
                                            left: '0px',
                                            padding: '16px',
                                            display: 'flex',
                                            justifyContent: 'end',

                                        }}>
                                            <Button variant="contained" size="medium" disabled={!dirty} type={'submit'} sx={{
                                                backgroundColor: 'rgba(51, 55, 255, 1)',
                                                borderRadius: '30px',
                                                fontWeight: 500,
                                            }}>
                                                Save Changes
                                            </Button>
                                        </Box>
                                    </Box>
                                </form>
                            )}
                        />


                    </Box>

                </Box>

            </Box>
        </Box>


    )
})


//     < div
// className = {'ml-[288px] w-[85%] h-full flex '} >
//     < div
// className = {'w-full h-full '} >
//     < Header
// className = {'pt-5 ml-5'}
// text = {'Products'}
// />
// <div className={
//     'border-b-1'
// }>
//     <TableTest rows={[
//         {
//             registered: State?.subscription?.createdAt,
//             productType: State?.subscription?.productType,
//             subscription: State?.subscription?.subscription,
//             key: State?.key,
//             expired: State?.subscription?.expiredAt,
//             days: State?.subscription?.expiredDays,
//             firstName: State?.user?.firstName,
//             lastName: State?.user?.lastName,
//             phoneNumber: State?.user?.phoneNumber,
//             email: State?.user?.email,
//         }
//
//     ]}
//                columns={['Registered', 'Product Type', 'Subscription', 'Key', 'Expired', 'Days', 'First Name', 'Last  Name', 'Phone Number', 'Email']}
//                tdClass={'pr-2 text-start text-base'}
//                thClass={'text-start text-gray-light font-normal pr-2 '}/>
//     {/*<Table*/}
//     {/*    name={'communication'}*/}
//     {/*    tableName={['Registered', 'Product Type', 'Subscription', 'Key', 'Expired', 'Days', 'First Name', 'Last  Name', 'Phone Number', 'Email']}*/}
//     {/*    data={[*/}
//     {/*        {*/}
//     {/*            registered: State?.subscription?.createdAt,*/}
//     {/*            productType:  State?.subscription?.productType,*/}
//     {/*            subscription: State?.subscription?.subscription,*/}
//     {/*            key: State?.key ,*/}
//     {/*            expired:  State?.subscription?.expiredAt,*/}
//     {/*            days:  State?.subscription?.expiredDays,*/}
//     {/*            firstName: State?.user?.firstName,*/}
//     {/*            lastName:  State?.user?.lastName,*/}
//     {/*            phoneNumber:  State?.user?.phoneNumber,*/}
//     {/*            email: State?.user?.email ,*/}
//     {/*        }*/}
//     {/*    ]}/>*/}
// </div>
// <div className={'flex h-full w-full py-4  overflow-x-scroll'}>
//     <div className={'flex ml-3 space-x-4'}>
//     </div>
// </div>
//
// </div>
// </div>